import React, { useState } from "react";
import { Container, GroupSquare, Square, StyledRow } from "./styles";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdb-react-ui-kit";
import {
  IoIosArrowDropupCircle,
  IoIosArrowDropdownCircle,
} from "react-icons/io";
import {
  MdDoneAll,
  MdOutlineCancel,
  MdOutlineFlag,
  MdOutlineNotificationsActive,
  MdOutlineNotificationsNone,
  MdOutlineRefresh,
  MdOutlineVisibilityOff,
} from "react-icons/md";

function UpdatePricesErrors() {
  const [expandedRow, setExpandedRow] = useState(null); // Mudança aqui

  const handleRowToggle = (idTransacao) => {
    setExpandedRow((prevExpandedRow) =>
      prevExpandedRow === idTransacao ? null : idTransacao
    );
  };

  // status:
  // - Notificar
  // - Notificado - problema resolvido
  // - Notificado - nao ha etiquetas com erro
  // - Notificado - nada detectado
  // - Notificado - tela apagada

  const [infos, setInfos] = useState([
    {
      nomeLM: "Viaplus 7000 - fibras cx 18kg",
      nroEtiquetas: 5,
      nroEtiquetasComErro: 5,
      idTransacao: "1-001f-e5284f81057606450809ea",
      status: "Notificar",
      infos: [
        {
          mac: "000B573F3A5A",
          modulo: "1",
          corredor: "1",
          secao: "Elétrica",
          rssi: "-75",
          status: "Sem comunicação",
        },
        {
          mac: "000B573F3A5B",
          modulo: "2",
          corredor: "2",
          secao: "Ferramentas",
          rssi: "-70",
          status: "Ativa",
        },
      ],
    },
    {
      nomeLM: "Mart Comb 800W HR2470 220V+acess Makita",
      nroEtiquetas: 6,
      nroEtiquetasComErro: 5,
      idTransacao: "1-001f-e5284f81057606450809eb",
      status: "Notificar",
      infos: [
        {
          mac: "000B573F3A6A",
          modulo: "1",
          corredor: "3",
          secao: "Pintura",
          rssi: "-65",
          status: "Sem comunicação",
        },
      ],
    },
    {
      nomeLM: "ARG ACIII MULTIUSO CZ 20KG AXTON",
      nroEtiquetas: 4,
      nroEtiquetasComErro: 2,
      idTransacao: "1-001f-e5284f81057606450809ec",
      status: "Notificado - problema resolvido",
      infos: [
        {
          mac: "000B573F3A7A",
          modulo: "1",
          corredor: "4",
          secao: "Jardinagem",
          rssi: "-80",
          status: "Inativa",
        },
      ],
    },
    {
      nomeLM: "Purificador refrig pla PE12G biv cz-elec",
      nroEtiquetas: 4,
      nroEtiquetasComErro: 2,
      idTransacao: "1-001f-e5284f81057606450809ed",
      status: "Notificado - nao ha etiquetas com erro",
      infos: [
        {
          mac: "000B573F3A7A",
          modulo: "1",
          corredor: "4",
          secao: "Jardinagem",
          rssi: "-80",
          status: "Inativa",
        },
      ],
    },
    {
      nomeLM: "Duch 1j acqua plus 1990cstd-deca",
      nroEtiquetas: 4,
      nroEtiquetasComErro: 2,
      idTransacao: "1-001f-e5284f81057606450809ee",
      status: "Notificado - nada detectado",
      infos: [
        {
          mac: "000B573F3A7A",
          modulo: "1",
          corredor: "4",
          secao: "Jardinagem",
          rssi: "-80",
          status: "Inativa",
        },
      ],
    },
    {
      nomeLM: "CIMENTO CPII 32 TODAS AS OBRA 50KG VOT",
      nroEtiquetas: 4,
      nroEtiquetasComErro: 2,
      idTransacao: "1-001f-e5284f81057606450809ef",
      status: "Notificado - tela apagada",
      infos: [
        {
          mac: "000B573F3A7A",
          modulo: "1",
          corredor: "4",
          secao: "Jardinagem",
          rssi: "-80",
          status: "Inativa",
        },
      ],
    },
  ]);

  const [columnSelectedSort, setColumnSelectedSort] = useState({
    columnName: "store",
    crescent: true,
  });

  const sortTableByColumn = (
    primaryColumnKey,
    controllingColumnKey,
    isNumber = true
  ) => {
    const { columnName, crescent } = columnSelectedSort;
    const isControllingColumn = columnName === controllingColumnKey;
    const newCrescent = isControllingColumn ? !crescent : true;

    const sortedData = sortByColumn(
      infos,
      primaryColumnKey,
      newCrescent,
      isNumber
    );

    setInfos(sortedData);

    setColumnSelectedSort({
      columnName: controllingColumnKey,
      crescent: newCrescent,
    });
  };

  function sortByColumn(data, fieldName, crescent = false, isNumber = true) {
    const sortedData = [...data].sort((a, b) => {
      if (isNumber) {
        return crescent
          ? a[fieldName] - b[fieldName]
          : b[fieldName] - a[fieldName];
      } else {
        return crescent
          ? a[fieldName].localeCompare(b[fieldName], undefined, {
              sensitivity: "base",
            })
          : b[fieldName].localeCompare(a[fieldName], undefined, {
              sensitivity: "base",
            });
      }
    });
    return sortedData;
  }

  return (
    <Container>
      <h3 className="d-flex align-items-left">
        Produtos (LMs) com Erro de Atualização
      </h3>

      <MDBTable align="start" hover responsive>
        <MDBTableHead>
          <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
            <th scope="col" style={{ fontWeight: "bold", cursor: "pointer" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Nome do LM</span>
              </div>
            </th>
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() =>
                sortTableByColumn("nroEtiquetas", "nroEtiquetas", true)
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Número de etiquetas</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "nroEtiquetas" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() =>
                sortTableByColumn(
                  "nroEtiquetasComErro",
                  "nroEtiquetasComErro",
                  true
                )
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Número de etiquetas com erro</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "nroEtiquetasComErro" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              ID da transação
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}></th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {infos.map((store) => (
            <React.Fragment key={store.idTransacao}>
              <StyledRow
                className={expandedRow === store.idTransacao ? "expanded" : ""} // Usa o StyledRow em vez de <tr>
                onClick={() => handleRowToggle(store.idTransacao)}
              >
                <td>{store.nomeLM}</td>
                <td>{store.nroEtiquetas}</td>
                <td>{store.nroEtiquetasComErro}</td>
                <td>{store.idTransacao}</td>
                <td>
                  {expandedRow === store.idTransacao ? (
                    <GroupSquare>
                      {store.status !== "Notificar" && (
                        <Square color="True" shadow="True">
                          {store.status ===
                            "Notificado - problema resolvido" && (
                            <MdDoneAll className="icons" />
                          )}
                          {store.status ===
                            "Notificado - nao ha etiquetas com erro" && (
                            <MdOutlineFlag className="icons" />
                          )}
                          {store.status === "Notificado - nada detectado" && (
                            <MdOutlineVisibilityOff className="icons" />
                          )}
                          {store.status === "Notificado - tela apagada" && (
                            <MdOutlineCancel className="icons" />
                          )}
                        </Square>
                      )}
                      <Square color="True" shadow="True">
                        {store.status === "Notificar" ? (
                          <MdOutlineNotificationsActive className="icons" />
                        ) : (
                          <MdOutlineNotificationsNone className="icons" />
                        )}
                      </Square>
                      <Square color="True" shadow="True">
                        <MdOutlineRefresh className="icons" />
                      </Square>
                    </GroupSquare>
                  ) : (
                    <GroupSquare>
                      <Square color="#ffffff">
                        <MDBIcon fas icon="ellipsis-v" />
                      </Square>
                    </GroupSquare>
                  )}
                </td>
              </StyledRow>
              {expandedRow === store.idTransacao && (
                <tr>
                  <td colSpan="5">
                    <div style={{ padding: "10px 0" }}>
                      <MDBTable align="start" hover responsive>
                        <MDBTableHead>
                          <tr
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            <th scope="col" style={{ fontWeight: "bold" }}>
                              MAC
                            </th>
                            <th scope="col" style={{ fontWeight: "bold" }}>
                              Módulo
                            </th>
                            <th scope="col" style={{ fontWeight: "bold" }}>
                              Corredor
                            </th>
                            <th scope="col" style={{ fontWeight: "bold" }}>
                              Seção
                            </th>
                            <th scope="col" style={{ fontWeight: "bold" }}>
                              RSSI
                            </th>
                            <th scope="col" style={{ fontWeight: "bold" }}>
                              Status
                            </th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {store.infos.map((info, index) => (
                            <tr
                              key={index}
                              className="table-light text-center"
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              <td>{info.mac}</td>
                              <td>{info.modulo}</td>
                              <td>{info.corredor}</td>
                              <td>{info.secao}</td>
                              <td>{info.rssi}</td>
                              <td>{info.status}</td>
                            </tr>
                          ))}
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </MDBTableBody>
      </MDBTable>
    </Container>
  );
}

export default UpdatePricesErrors;
