import React from "react";
import { Bar } from "react-chartjs-2";
import { ChartContainer } from "./styles";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const BarChart = ({ data, options }) => {
  const updatedOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      datalabels: {
        display: true,
        align: "center",
        anchor: "center",
        color: "#062b35",
        font: {
          weight: "normal",
        },
        rotation: 270,
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex];
        },
      },
      legend: {
        display: false, // Remover a legenda
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          display: true,
        },
      },
    },
  };

  return (
    <ChartContainer>
      <Bar data={data} options={updatedOptions} />
    </ChartContainer>
  );
};

export default BarChart;
