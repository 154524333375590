import styled from "styled-components";

export const Container = styled.div`
  padding: 2% 5%;
`;

export const ButtonContainer = styled.div`
  position: relative;
  display: inline-block;
  background-color: #87c53a;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;

  button {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
`;

export const CalendarContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 40px; /* Ajuste a posição do calendário conforme necessário */
  left: 0;
`;

export const TableContainer = styled.div`
  background-color: #eaf3e2;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px; /* Adicione margem para espaçamento */
  overflow-x: auto; /* Adiciona barra de rolagem horizontal quando necessário */
`;

export const Title = styled.h6`
  font-weight: bold;
  margin-bottom: 0; /* Remove a margem inferior para melhor alinhamento */
  font-size: 20px;
  flex-grow: 1;
  text-align: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 60px; /* Defina uma altura fixa ou ajuste conforme necessário */
`;

export const LastUpdateText = styled.p`
  color: #7b8794;
  font-size: 14px;
  margin-top: 10px;
`;

export const ContentContainer = styled.div`
  background-color: #eaf3e2;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const TableSection = styled.div`
  background-color: #eaf3e2;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SectionButton = styled.button`
  background-color: #87c53a;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
`;

export const SectionTitle = styled.h6`
  font-weight: bold;
  text-align: center; /* Centraliza o título */
`;

export const LMTableContainer = styled.div`
  overflow-x: auto; /* Adiciona barra de rolagem horizontal quando necessário */
`;
