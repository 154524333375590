import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "./styles";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import api from "../../services/api";
import { ButtonEye } from "../../components/ButtonEye/ButtonEye";
import {
  IoIosArrowDropupCircle,
  IoIosArrowDropdownCircle,
} from "react-icons/io";

function Stores() {
  const navigate = useNavigate();
  const [stores, setStores] = useState([]);
  const [columnSelectedSort, setColumnSelectedSort] = useState({
    columnName: "store",
    crescent: true,
  });

  useEffect(() => {
    function getData() {
      try {
        api.get("/infos/dataDetailsProfundidadeZero").then((response) => {
          setStores(response.data);
        });
      } catch (error) {
        console.log(error);
      }
    }

    getData();
  }, []);

  function handleRowClick(store) {
    navigate(
      `/storemapsecoes?client_id=${store.client_id}&store_code=${store.store_code}&location=${store.location}`
    );
  }

  function sortByColumn(fieldName, crescent = false, isNumber = true) {
    const aux = [...stores];

    if (isNumber) {
      const auxSorted = aux.sort(
        (a, b) => a[0].infos[fieldName] - b[0].infos[fieldName]
      );
      setStores(crescent ? auxSorted : auxSorted.reverse());
      return;
    }

    const auxSorted = aux.sort((a, b) => {
      if (checkStringNumber(a[fieldName]) && checkStringNumber(b[fieldName]))
        return Number(a[fieldName]) - Number(b[fieldName]);
      return a[fieldName]
        .toLowerCase()
        .localeCompare(b[fieldName].toLowerCase());
    });

    setStores(crescent ? auxSorted : auxSorted.reverse());
  }

  function checkStringNumber(string) {
    return !isNaN(string);
  }

  return (
    <Container>
      <h3 className="d-flex align-items-left">Lojas</h3>

      <MDBTable align="start" hover responsive>
        <MDBTableHead>
          <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                const { columnName, crescent } = columnSelectedSort;
                sortByColumn(
                  "location",
                  columnName === "store" ? !crescent : true,
                  false
                );
                setColumnSelectedSort({
                  columnName: "store",
                  crescent: columnName === "store" ? !crescent : true,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Loja (Código)</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "store" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ marginLeft: 5, position: "absolute" }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ marginLeft: 5, position: "absolute" }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                const { columnName, crescent } = columnSelectedSort;
                sortByColumn(
                  "commissionedESLs",
                  columnName === "commissionedESLs" ? !crescent : true
                );
                setColumnSelectedSort({
                  columnName: "commissionedESLs",
                  crescent:
                    columnName === "commissionedESLs" ? !crescent : true,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Número de Etiquetas comissionadas</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "commissionedESLs" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                const { columnName, crescent } = columnSelectedSort;
                sortByColumn(
                  "productsCommissioned",
                  columnName === "productsCommissioned" ? !crescent : true
                );
                setColumnSelectedSort({
                  columnName: "productsCommissioned",
                  crescent:
                    columnName === "productsCommissioned" ? !crescent : true,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Número de Produtos comissionados</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "productsCommissioned" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                const { columnName, crescent } = columnSelectedSort;
                sortByColumn(
                  "notCommissionedESLs",
                  columnName === "notCommissionedESLs" ? !crescent : true
                );
                setColumnSelectedSort({
                  columnName: "notCommissionedESLs",
                  crescent:
                    columnName === "notCommissionedESLs" ? !crescent : true,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>
                  Etiquetas somente instaladas <br />
                  (não comissionados)
                </span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "notCommissionedESLs" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                const { columnName, crescent } = columnSelectedSort;
                sortByColumn(
                  "totalESLs",
                  columnName === "totalESLs" ? !crescent : true
                );
                setColumnSelectedSort({
                  columnName: "totalESLs",
                  crescent: columnName === "totalESLs" ? !crescent : true,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Número de etiquetas da Loja</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "totalESLs" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                const { columnName, crescent } = columnSelectedSort;
                sortByColumn(
                  "isntValidESLs",
                  columnName === "isntValidESLs" ? !crescent : true
                );
                setColumnSelectedSort({
                  columnName: "isntValidESLs",
                  crescent: columnName === "isntValidESLs" ? !crescent : true,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Etiquetas que necessitam de manutenção</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "isntValidESLs" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Ver Seções
            </th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {stores.map((store) => (
            <tr
              key={store.store_code}
              className="table-light text-center"
              style={{ textAlign: "center", verticalAlign: "middle" }}
            >
              <td>{`${store.location} (${store.store_code})`}</td>
              <td>{store.commissionedESLs}</td>
              <td>{store.productsCommissioned}</td>
              <td>{store.notCommissionedESLs}</td>
              <td>{store.totalESLs}</td>
              <td>
                {store.isntValidESLs < 0 ? (
                  <span className="text-danger">{store.isntValidESLs}</span>
                ) : (
                  <span>{store.isntValidESLs}</span>
                )}
              </td>
              <td onClick={() => handleRowClick(store)}>
                <ButtonEye />
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </Container>
  );
}

export default Stores;
