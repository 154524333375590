import React from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import moment from "moment";
import { TableContainer, CircleCell, StatusCell, ClickableRow } from "./styles";
import { FaExclamationCircle, FaSync, FaCheckCircle } from "react-icons/fa";

const PriceUpdateTable = ({ data, title, onRowClick, tipo }) => {
  const getStatusIcon = (status) => {
    switch (status) {
      case "Com Erro":
        return <FaExclamationCircle style={{ marginRight: 8 }} />;
      case "Em Andamento":
        return <FaSync style={{ marginRight: 8 }} />;
      default:
        return <FaCheckCircle style={{ marginRight: 8 }} />;
    }
  };

  return (
    <TableContainer>
      <MDBTable responsive>
        <MDBTableHead>
          <tr>
            <th>
              <strong>Status</strong>
            </th>
            <th>
              <strong>{tipo}</strong>
            </th>
            <th>
              <strong>LM's atualizados</strong>
            </th>
            <th>
              <strong>Última Atualização</strong>
            </th>
            <th>
              <strong>Erro</strong>
            </th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {data.map((item, index) => (
            <ClickableRow key={index} onClick={() => onRowClick(item)}>
              <StatusCell
                className={
                  item.status === "Com Erro"
                    ? "status-error"
                    : item.status === "Em Andamento"
                    ? "status-in-progress"
                    : ""
                }
              >
                {getStatusIcon(item.status)}
                <span>{item.status}</span>
              </StatusCell>
              <td>{item.name}</td>
              <td>{item.updatedLMs}</td>
              <td>{moment(item.lastUpdate).format("DD/MM/YYYY")}</td>
              <td className="error-count">
                {item.errorCount > 0 && (
                  <CircleCell>{item.errorCount}</CircleCell>
                )}
              </td>
            </ClickableRow>
          ))}
        </MDBTableBody>
      </MDBTable>
    </TableContainer>
  );
};

export default PriceUpdateTable;
