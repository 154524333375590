import React, { useState } from "react";
import { ButtonContainer, DropdownContainer, DropdownItem } from "./styles";
import { IoMdArrowDropdown } from "react-icons/io";

const DropdownMenu = ({ items, selectedItem, onItemSelect, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ButtonContainer>
      <button onClick={() => setIsOpen(!isOpen)}>
        <strong>
          {selectedItem || title} <IoMdArrowDropdown />
        </strong>
      </button>
      {isOpen && (
        <DropdownContainer>
          {items.map((item) => (
            <DropdownItem
              key={item}
              onClick={() => {
                onItemSelect(item);
                setIsOpen(false);
              }}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownContainer>
      )}
    </ButtonContainer>
  );
};

export default DropdownMenu;
