import styled from "styled-components";

export const Container = styled.div`
  padding: 2% 5%;

  h3 {
    font-weight: bold;
  }

  .icons {
    font-size: 18px;
  }
`;

export const Square = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${(props) =>
    props.shadow === "True" ? "0 0 5px rgba(0, 0, 0, 0.1)" : 0};
  background-color: ${(props) => (props.color === "True" ? "#87c53a" : 0)};
  border-radius: 5px;
  width: 35px;
  height: 35px;
  margin: 5px;
`;

export const GroupSquare = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
`;

// Novo estilo para a linha da tabela
export const StyledRow = styled.tr`
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #eaf3e2; /* Cor de fundo no hover */
  }

  &.expanded {
    background-color: #eaf3e2; /* Cor de fundo fixa para linha expandida */
  }

  &.expanded:hover {
    background-color: #eaf3e2; /* Mantém a cor de fundo no hover quando expandido */
  }
`;
