// NotFound.js
import { Navigate } from "react-router-dom";

function NotFound() {
  const token = localStorage.getItem("token");

  return <>{token ? <Navigate to="/home" /> : <Navigate to="/login" />}</>;
}

export default NotFound;
