// ESLDetails.js
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Container, Square } from "./styles";
import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import "material-symbols";
import api from "../../services/api";
import BlockedInput from "../../components/BlockedInput/BlockedInput";

const ESLDetails = () => {
  const [esl, setESL] = useState([]);
  const [actions, setActions] = useState();
  const mac = useLocation().pathname.split("/")[2];

  useEffect(() => {
    api.get(`/esls/details/mac/${mac}`).then((response) => {
      console.log(response.data)
      setESL(response.data[0]);
    });
    api.get(`/logs/mac/${mac}`).then((response) => {
      console.log(response.data[0].actions);
      setActions(response.data[0].actions);
    });
  }, [mac]);

  return (
    <Container>
      <MDBRow className="text-start">
        <h3>Etiqueta MAC {mac}</h3>

        <MDBRow>
          <MDBCol md="7" className="text-start">
            <MDBRow>
              <MDBCol style={{ marginTop: 10}} className="d-flex align-items-center">
                <h5><span style={{ fontWeight: 'bolder' }}>Nome completo do produto: </span>{esl.product_title}</h5>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol style={{ marginTop: 10}} className="d-flex align-items-center">
                <h5><span style={{ fontWeight: 'bolder' }}>Código LM: </span>{esl.product_gtin}</h5>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol style={{ marginTop: 10}} className="d-flex align-items-center">
                <h5><span style={{ fontWeight: 'bolder' }}>Código de barras: </span>{esl.product_internal_client_code}</h5>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol style={{ marginTop: 10}} className="d-flex align-items-center">
                <h5><span style={{ fontWeight: 'bolder' }}>Preço atual: </span>{esl.msg_timestamp ? esl.product_price : ""}</h5>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol style={{ marginTop: 10}} className="d-flex align-items-center">
                <h5><span style={{ fontWeight: 'bolder' }}>Última atualização de preço: </span>{esl.msg_timestamp ? moment
                  .utc(esl.msg_timestamp)
                  .format("HH:mm:ss - DD/MM/YYYY") : ""}</h5>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol style={{ marginTop: 10}} className="d-flex align-items-center">
                <h5><span style={{ fontWeight: 'bolder' }}>Avaliação: </span>{esl.product_rating}</h5>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol style={{ marginTop: 20}} className="d-flex align-items-center">
                <h5><span style={{ fontWeight: 'bolder' }}>Localização física da etiqueta</span></h5>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol style={{ marginTop: 10}} className="d-flex align-items-center">
                <h5><span style={{ fontWeight: 'bolder' }}>Seção: </span>{esl.depth_0}</h5>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol style={{ marginTop: 10}} className="d-flex align-items-center">
                <h5><span style={{ fontWeight: 'bolder' }}>Corredor: </span>{esl.depth_1}</h5>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol style={{marginTop: 10}} className="d-flex align-items-center">
                <h5><span style={{ fontWeight: 'bolder' }}>Módulo: </span>{esl.depth_2}</h5>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol md="5" className="text-start">
            <MDBRow>
              <MDBCol
                md="12"
                className="text-center"
                style={{ marginBottom: "2%" }}
              >
                {esl.render ? (
                  <img
                    src={`data:image/jpeg;base64,${esl.render.img}`}
                    alt={esl.product_title}
                    width="40%"
                  />
                ) : (
                  <img
                    src="https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg"
                    alt={esl.product_title}
                    width="40%"
                  />
                )}
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBRow>

      { actions && actions.length > 0 ?
        <MDBRow className="text-start" style={{ marginTop: "10px" }}>
          <MDBTable align="center" className="text-center">
            <MDBTableHead>
              <tr>
                <th scope="col">Data e hora</th>
                <th scope="col">Ação</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {actions?.map((action) => (
                <tr className="table-light">
                  <td>
                    {moment
                      .utc(action.timestamp)
                      .format("HH:mm:ss - DD/MM/YYYY")}
                  </td>
                  {action.action === "commission" && <td>Comissionada</td>}
                  {action.action === "uncommission" && <td>Descomissionada</td>}
                  {action.action === "install" && <td>Instalada</td>}
                  {action.action === "uninstall" && <td>Desinstalada</td>}
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </MDBRow> : undefined
      } 
    </Container>
  );
};

export default ESLDetails;
