import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const ImgLogo = styled.div`
  margin-top: 3%;
  margin-bottom: 3%;
  display: flex; /* Ativa o Flexbox */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
`;

export const Square = styled.div`
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 20px;
  padding: 20px 20px 20px 20px;
  box-shadow: 0 0 5px grey;
  width: 70%;
  min-width: 350px;
  h2 {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: black;
  }

  h6 {
    font-weight: bold;
    color: black;
  }

  form {
    width: 100%;
    a {
      text-decoration: none;
    }
  }
`;

export const Button = styled.div`
  button {
    background: #78be20;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(211, 47, 47, 0.24);
    color: #ffffff;
    border: 0;
    height: 50px;
    width: 47%;
    margin-top: 3%;
  }
`;
