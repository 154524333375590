// Card/styles.js
import styled from "styled-components";

export const CardContainer = styled.div`
  background-color: #eaf3e2;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Preenche toda a largura */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%; /* Garante que o conteúdo preencha a largura do container */
`;

export const IconContainer = styled.div`
  background-color: ${({ iconColor }) => iconColor || "#a8d5a2"};
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;

  svg {
    width: 24px;
    height: 24px;
    color: white;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(
    100% - 65px
  ); /* Ajusta a largura com base no espaço do ícone e margem */
`;

export const Title = styled.h2`
  color: #7b8794;
  font-size: 15px;
  margin: 0;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Number = styled.p`
  color: #243b53;
  font-size: 20px;
  margin: 0;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; /* Preenche toda a largura disponível no contêiner de texto */
  text-align: left; /* Garante que o texto esteja alinhado à esquerda */
`;
