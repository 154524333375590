import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Infos } from "./styles";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

import api from "../../services/api";
import Input from "../../components/Input/Input";
import ButtonGrey from "../../components/ButtonGrey/ButtonGrey";
import ButtonGreen from "../../components/ButtonGreen/ButtonGreen";
import { toast } from "react-toastify";

function SearchProduct() {
  const [name, setName] = useState("");
  const [productsNames, setProductsNames] = useState([]);
  const [productsNamesFiltered, setProductsNamesFiltered] = useState([]);
  const [locations, setLocations] = useState([]);

  function handleSearchName() {
    api
      .get(`/search/products/title/${name}`)
      .then((response) => {
        setLocations(response.data);
      })
      .catch(() => {
        setLocations([]);
      });
  }

  function filterProductsNames(value) {
    const filteredOptions = productsNames.filter(option => option.toLowerCase().includes(value.toLowerCase()))

    setProductsNamesFiltered(filteredOptions)
  }

  function handleFilterProductsNames(value){
    setName(value)
    setProductsNamesFiltered([])

    handleSearchName()
  }

  useEffect(() => {
    async function getProductsNames() {
      try {
        const response = await api.get('/search/products')

        const aux = []
        response.data.map(item => aux.push(item.productTitle))
        setProductsNames(aux)
      } catch (error) {
        toast.error('Não foi possível recuperar os dados')
      }
    }

    getProductsNames()
  }, [])

  return (
    <Container>
      <h3>Produtos</h3>

      <Infos>
        <MDBRow style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <MDBCol md="2" className="d-flex align-items-center">
            <h5>Buscar produto: </h5>
          </MDBCol>
          <MDBCol md="7">
            <Input
              type="text"
              autoComplete="off"
              name="gtin"
              placeholder="Digite o nome do produto"
              value={name}
              style={name !== "" && productsNamesFiltered.length > 0 ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottom: 'none'} : undefined}
              onChange={(e) => {
                setName(e.target.value)
                filterProductsNames(e.target.value)
              }}
            />

            {name !== "" && productsNamesFiltered.length > 0 && <div style={{ display: 'flex', flex: 1, position: 'relative', justifyContent: 'center'}}>
              <div style={{ position: 'absolute', borderTop: '1px solid #cbccd1', width: '90%', zIndex: 1 }} />
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start', position: 'absolute', backgroundColor: 'white', border: '1px solid #cbccd1', borderTop: 'none', borderRadius: 5, borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
                {productsNamesFiltered.map((item, index) => (<div key={index} id="options" onClick={() => handleFilterProductsNames(item)} style={{ marginTop: 4, marginBottom: 4, cursor: 'pointer', width: '100%', display: 'flex', alignItems: 'center', paddingLeft: 10,}}><span>{item}</span></div>))}
              </div>
            </div>}
          </MDBCol>
          <MDBCol md="3">
            <ButtonGreen
              type="submit"
              onClick={handleSearchName}
              label="Pesquisar"
            />
          </MDBCol>
        </MDBRow>

        <MDBTable align="start" hover>
          <MDBTableHead>
            <tr>
              <th scope="col">SGLN</th>
              <th scope="col">Loja</th>
              <th scope="col">Seção</th>
              <th scope="col">Corredor</th>
              <th scope="col">Módulo</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {locations.length === 0 ? (
              <tr>
                <td colSpan="5">
                  <p className="text-center" style={{ marginTop: "1%" }}>
                    Nenhuma localização encontrada.
                  </p>
                </td>
              </tr>
            ) : (
              locations.map((location) => (
                <tr key={location.sgln} className="table-light">
                  <td>{name}</td>
                  <td>{location.store_code}</td>
                  <td>{location.depth_0}</td>
                  <td>{location.depth_1}</td>
                  <td>{location.depth_2}</td>
                </tr>
              ))
            )}
          </MDBTableBody>
        </MDBTable>
      </Infos>
    </Container>
  );
}

export default SearchProduct;
