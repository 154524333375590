import React from "react";
import { CardContainer, ColumnContainer, HeaderContainer } from "./styles";

const Card = ({ produto, quantidade, min, max }) => {
  return (
    <CardContainer quantidade={quantidade} min={min} max={max}>
      <div>{produto}</div>
      <div>
        <strong>{quantidade}</strong>
      </div>
    </CardContainer>
  );
};

const Column = ({ title, items, min, max }) => {
  // Sort items by quantidade in descending order
  const sortedItems = [...items].sort((a, b) => b.quantidade - a.quantidade);

  return (
    <ColumnContainer>
      {sortedItems.map((item, index) => (
        <Card
          key={index}
          produto={item.produto}
          quantidade={item.quantidade}
          min={min}
          max={max}
        />
      ))}
    </ColumnContainer>
  );
};

const ColumnWithCards = ({ data }) => {
  // Flatten the items to get a single list of quantities
  const allItems = data.flatMap((column) => column.items);
  const quantities = allItems.map((item) => item.quantidade);

  // Calculate min and max values
  const min = Math.min(...quantities);
  const max = Math.max(...quantities);

  return (
    <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
      {data.map((columnData, index) => (
        <Column
          key={index}
          title={columnData.title}
          items={columnData.items}
          min={min}
          max={max}
        />
      ))}
    </div>
  );
};

export default ColumnWithCards;
