import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2% 5%;

  h3 {
    font-weight: bold;
  }
`;
