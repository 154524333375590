// Login.js

import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Container, ImgLogo, Square } from "./styles";
import ButtonGreen from "../../components/ButtonGreen/ButtonGreen";
import Input from "../../components/Input/Input";
import imgLogoLeroy from "../../img/logo-leroy.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useAuth from "../../hooks/auth";

const Login = () => {
  const { login } = useAuth();
  const [ldap, setLdap] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const responseLogin = await login(ldap, password); // chama a função login do hook useAuth
      if (responseLogin) {
        navigate("/home");
      } else {
        toast.error("Email ou senha incorretos");
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log("err.message", err.message);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <Container>
        <MDBRow style={{ width: "100%" }}>
          <MDBCol lg="6">
            {/* Logo da Leroy*/}
            <ImgLogo>
              <img src={imgLogoLeroy} alt="Logo Leroy Merlin" />
            </ImgLogo>
          </MDBCol>
          <MDBCol
            lg="6"
            className="text-start"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Square>
              <form onSubmit={handleSubmit}>
                <h6>LDAP</h6>
                <Input
                  type="text"
                  autoComplete="email"
                  name="email"
                  value={ldap || ""}
                  onChange={(event) => setLdap(event.target.value)}
                />
                <h6>Senha</h6>
                <Input
                  type="password"
                  autoComplete="current-password"
                  name="senha"
                  value={password || ""}
                  onChange={(event) => setPassword(event.target.value)}
                />
                Ainda não tem cadastro?{" "}
                <a href="/register" style={{ color: "green" }}>
                  Clique aqui
                </a>
                <MDBRow style={{ marginTop: 12}}>
                  <MDBCol>
                    <ButtonGreen type="submit" label="Login" />
                  </MDBCol>
                </MDBRow>
              </form>
            </Square>
          </MDBCol>
        </MDBRow>
      </Container>
    </>
  );
};

export default Login;
