// Routes.js
import { Route, Routes as RoutesDom, useLocation } from "react-router-dom";

import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Stores from "./pages/Stores/Stores";
import ProductUpdate from "./pages/ProductUpdate/ProductUpdate";
import ESLsUpdate from "./pages/ESLsUpdate/ESLsUpdate";
import Commissions from "./pages/Commissions/Commissions";
import Installs from "./pages/Installs/Installs";
import StoreMapSecoes from "./pages/StoreMapSecoes/StoreMapSecoes";
import StoreMapCorredores from "./pages/StoreMapCorredores/StoreMapCorredores";
import StoreMapModulos from "./pages/StoreMapModulos/StoreMapModulos";
import ESLs from "./pages/ESLs/ESLs";
import ESLDetails from "./pages/ESLDetails/ESLDetails";
import InsertESL from "./pages/InsertESL/InsertESL";
import InsertGateway from "./pages/InsertGateway/InsertGateway";
import InsertSGLN from "./pages/InsertSGLN/InsertSGLN";
import NotFound from "./pages/NotFound";
import Navbar from "./components/Navbar/Navbar";
import Register from "./pages/Register/Register";
import InserirAVRO from "./pages/InserirAVRO/InserirAVRO";
import PrintQRCode from "./pages/PrintQRCode/PrintQRCode";
import SearchLocalization from "./pages/SearchLocalization/SearchLocalization";
import SearchProduct from "./pages/SearchProduct/SearchProduct";
import ProductUpdateFilter from "./pages/ProductUpdateFilter/ProductUpdateFilter";
import EslsUpdateFilter from "./pages/ESLsUpdateFilter/ESLsUpdateFilter";
import ProductChart from "./pages/ProductChart/ProductChart";
import UpdateShopsDetails from "./pages/UpdateShop/UpdateShop";
import UpdatePricesSections from "./pages/UpdatePricesSections/UpdatePricesSections";
import UpdatePricesStores from "./pages/UpdatePricesStores/UpdatePricesStores";
import InfosStore from "./pages/InfosStore/InfosStore";
import UpdatePricesErrors from "./pages/UpdatePricesErrors/UpdatePricesErrors";
// import TesteLooker from "./pages/TesteLooker/TesteLooker";

const Routes = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");

  // Verifica se a rota atual é a de login
  const isLoginPage = location.pathname === "/login";
  const isRegisterPage = location.pathname === "/register";

  return (
    <>
      {!isLoginPage && !isRegisterPage && <Navbar />}
      {/* Se não for a rota de login, renderiza o Navbar */}
      {token ? (
        <RoutesDom>
          <Route index path="/home" element={<UpdatePricesStores />} />
          <Route path="stores" element={<Stores />} />
          <Route path="updateESLs" element={<Home />} />
          <Route path="productUpdate" element={<ProductUpdate />} />
          <Route
            path="productUpdateFilter/filter"
            element={<ProductUpdateFilter />}
          />
          <Route path="eslUpdate/filter/error" element={<EslsUpdateFilter />} />
          <Route path="eslUpdate/:transactionId" element={<ESLsUpdate />} />
          <Route path="installs" element={<Installs />} />
          <Route path="commissions" element={<Commissions />} />
          <Route path="storemapsecoes" element={<StoreMapSecoes />} />
          <Route path="storemapcorredores" element={<StoreMapCorredores />} />
          <Route path="storemapmodulos" element={<StoreMapModulos />} />
          <Route path="esls" element={<ESLs />} />
          <Route path="details/:mac" element={<ESLDetails />} />
          <Route path="insertESL" element={<InsertESL />} />
          <Route path="insertGateway" element={<InsertGateway />} />
          <Route path="insertSGLN" element={<InsertSGLN />} />
          <Route path="printQRCode" element={<PrintQRCode />} />
          <Route path="inserirAVRO" element={<InserirAVRO />} />
          <Route path="searchLocalization" element={<SearchLocalization />} />
          <Route path="searchProduct" element={<SearchProduct />} />
          <Route path="productChart" element={<ProductChart />} />
          <Route path="updateShop" element={<UpdateShopsDetails />} />
          <Route path="updatePricesStores" element={<UpdatePricesStores />} />
          <Route
            path="updatePricesSections/:idStore"
            element={<UpdatePricesSections />}
          />
          <Route
            path="updatePricesErrors/:idStore/:idSections"
            element={<UpdatePricesErrors />}
          />
          <Route path="infosStore" element={<InfosStore />} />
          <Route path="*" element={<NotFound />} />
        </RoutesDom>
      ) : (
        <RoutesDom>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="*" element={<NotFound />} />
        </RoutesDom>
      )}
    </>
  );
};

export default Routes;
