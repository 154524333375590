import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Container } from "./styles";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import api from "../../services/api";
import { ButtonEye } from "../../components/ButtonEye/ButtonEye";
import { RxUpdate } from "react-icons/rx";
import { useSpring, animated } from "react-spring";
import {
  IoIosCheckmarkCircle,
  IoIosCloseCircle,
  IoIosHelpCircle,
} from "react-icons/io";
import { toast } from "react-toastify";

const EslsUpdate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { transactionId } = useParams();

  const {
    product_name = "Erro ao receber nome do produto",
    new_price = "Erro",
    old_price = "Erro",
    gtin = "Erro ao receber o gtin do produto",
    transaction_time = "Erro ao receber atualização"
  } = location.state || {};

  const [ESLs, setESLs] = useState([]);
  const [loading, setLoading] = useState(true);

  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [in_progress, setIn_progress] = useState(false);

  const animatedLoadingProps = useSpring({
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
    config: {
      duration: 800,
    },
    reset: true,
    cancel: !loading,
  });

  useEffect(() => {
    let isMounted = true
    async function getData() {
      try {
        const response = await api.get(
          `/productUpdate/transactionId/${transactionId}`
        );

        const esls_aux = [];

        response.data[0].esls.forEach((item) => {
          esls_aux.push({
            esl_mac: item.mac,
            esl_location: `${item.depth_0} / ${item.depth_1} / ${item.depth_2}`,
            status:
              item.update_status === "H"
                ? "Erro"
                : item.update_status === "P"
                ? "Em andamento"
                : "Sucesso",
          });
        });

        setESLs(esls_aux);

        if(isMounted) setTimeout(getData, 5000);
      } catch (error) {
        toast.error("Erro ao recuperar ESLs dessa transação");
      }
    }

    getData();

    return () => {
      isMounted = false
    };
  }, [loading]);

  function handleToDetailsESL(mac) {
    navigate(`/details/${mac}`);
  }

  function update(filter) {
    switch (filter) {
      case "success":
        setESLs(ESLs.filter((item) => item.status === "Sucesso"));
        break;
      case "in_progress":
        setESLs(ESLs.filter((item) => item.status === "Em andamento"));
        break;
      case "failed":
        setESLs(ESLs.filter((item) => item.status === "Erro"));
        break;
      default:
        break;
    }

    setLoading(true);
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: 16,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 16,
            }}
          >
            <h3>Atualizações de preços de etiquetas</h3>
            <span>
              <strong>Nome do produto:</strong> {product_name}
            </span>
            <span>
              <strong>Gtin:</strong> {gtin}
            </span>
            <span>
              {" "}
              <strong>Id da transação:</strong> {transactionId}
            </span>
            <span>
              {" "}
              <strong>Data/Hora da última atualização:</strong> {transaction_time}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span>
                <strong>Preço antigo: </strong> R$ {old_price}
              </span>
              <span style={{ marginLeft: 8 }}>
                <strong>Preço novo:</strong> R$ {new_price}
              </span>
            </div>
          </div>
        </div>
        {/* <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <span>Filtros:</span>
          <button onClick={() => update('success')} style={{ paddingLeft: 8, paddingRight: 8, borderWidth: success ? 2 : 1, borderColor: success ? 'rgb(120, 190, 32)' : 'black' }}>Sucessos</button>
          <button onClick={() => update('in_progress')} style={{ paddingLeft: 8, paddingRight: 8, borderWidth: in_progress ? 2 : 1, borderColor: in_progress ? 'rgb(120, 190, 32)' : 'black' }}>Em andamento</button>
          <button onClick={() => update('failed')} style={{ paddingLeft: 8, paddingRight: 8, borderWidth: failed ? 2 : 1, borderColor: failed ? 'rgb(120, 190, 32)' : 'black' }}>Erros</button>
        </div> */}
      </div>

      <MDBTable align="start" hover>
        <MDBTableHead>
          <tr>
            <th>MAC</th>
            <th>Seção/Corredor/Módulo</th>
            <th>Status</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {ESLs.map((ESL) => (
            <tr>
              <td
                onClick={() => handleToDetailsESL(ESL.esl_mac)}
                style={{ cursor: "pointer" }}
              >
                {ESL.esl_mac}
              </td>
              <td
                onClick={() => handleToDetailsESL(ESL.esl_mac)}
                style={{ cursor: "pointer" }}
              >
                {ESL.esl_location}
              </td>
              <td
                onClick={() => handleToDetailsESL(ESL.esl_mac)}
                style={{ cursor: "pointer" }}
              >
                <span
                  style={{
                    marginRight: 4,
                    color:
                      ESL.status === "Sucesso"
                        ? "#41A017"
                        : ESL.status === "Erro"
                        ? "#B42A27"
                        : "#C9A21B",
                  }}
                >
                  {ESL.status}
                </span>
                <span
                  style={{
                    color:
                      ESL.status === "Sucesso"
                        ? "#41A017"
                        : ESL.status === "Erro"
                        ? "#B42A27"
                        : "#C9A21B",
                  }}
                >
                  {ESL.status === "Sucesso" ? (
                    <IoIosCheckmarkCircle size={20} />
                  ) : ESL.status === "Erro" ? (
                    <IoIosCloseCircle size={20} />
                  ) : (
                    <IoIosHelpCircle size={20} />
                  )}
                </span>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </Container>
  );
};

export default EslsUpdate;
