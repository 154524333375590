import React, { useState, useEffect } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { ButtonContainer, CalendarContainer } from "./styles";
import { IoMdArrowDropdown } from "react-icons/io";

const PeriodButton = ({ startDate, endDate, onDatesChange }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);

  useEffect(() => {
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
  }, [startDate, endDate]);

  return (
    <ButtonContainer>
      <button onClick={() => setFocusedInput("startDate")}>
        Período:{" "}
        {localStartDate ? localStartDate.format("DD/MM/YYYY") : "Selecionar"} -{" "}
        {localEndDate ? localEndDate.format("DD/MM/YYYY") : "Selecionar"}{" "}
        <IoMdArrowDropdown />
      </button>
      {focusedInput && (
        <CalendarContainer>
          <DateRangePicker
            startDate={localStartDate}
            startDateId="your_unique_start_date_id"
            endDate={localEndDate}
            endDateId="your_unique_end_date_id"
            onDatesChange={({ startDate, endDate }) => {
              setLocalStartDate(startDate);
              setLocalEndDate(endDate);
              onDatesChange(startDate, endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            displayFormat="DD/MM/YYYY"
            isOutsideRange={() => false} // Permitir seleção de todas as datas
          />
        </CalendarContainer>
      )}
    </ButtonContainer>
  );
};

export default PeriodButton;
