import React, { useState } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import logoLeroy from "../../img/logo-leroy.svg";
import useAuth from "../../hooks/auth";

const NavItem = ({ href, children }) => (
  <MDBNavbarItem style={{ display: "flex", alignItems: "center" }}>
    <MDBNavbarLink href={href} style={{ color: "#062b35", fontWeight: "bold" }}>
      {children}
    </MDBNavbarLink>
  </MDBNavbarItem>
);

const DropdownItem = ({ to, children }) => (
  <Link to={to}>
    <MDBDropdownItem
      style={{
        color: "#062b35",
        padding: "10px",
        fontWeight: "bold",
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.backgroundColor = "#ADD585";
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = "#eaf3e2";
      }}
    >
      {children}
    </MDBDropdownItem>
  </Link>
);

const DropdownMenu = ({ title, items }) => (
  <MDBDropdown>
    <MDBDropdownToggle
      tag="a"
      className="nav-link"
      role="button"
      style={{ color: "#062b35", fontWeight: "bold" }}
    >
      {title}
    </MDBDropdownToggle>
    <MDBDropdownMenu
      style={{
        width: "100%",
        borderRadius: "0px",
        backgroundColor: "#eaf3e2",
      }}
    >
      {items.map((item, index) => (
        <DropdownItem key={index} to={item.to}>
          {item.label}
        </DropdownItem>
      ))}
    </MDBDropdownMenu>
  </MDBDropdown>
);

const Navbar = () => {
  const [showBasic, setShowBasic] = useState(false);
  const { logout } = useAuth();
  const SAP = localStorage.getItem("code");
  const isSAP = SAP === "012345678910";

  const navItems = [{ href: "/stores", label: "Mapa de ESLs" }];
  const dropdowns = [
    {
      title: "Acompanhar Atualizações de Preço",
      items: [
        { to: "/updateESLs", label: "Acompanhar atualizações do dia" },
        { to: "/productUpdate", label: "Acompanhar atualizações por produto" },
        { to: "/updateShop", label: "Acompanhar atualizações por loja" },
      ],
    },
    {
      title: "Localizar Produtos",
      items: [
        { to: "/searchlocalization", label: "Buscar por localização" },
        { to: "/searchproduct", label: "Buscar por nome" },
      ],
    },
    {
      title: "Visualizar Instalações e Comissionamentos",
      items: [
        { to: "/installs", label: "Visualizar Instalações/Desinstalações" },
        {
          to: "/commissions",
          label: "Visualizar Comissionamentos/Descomissionamentos",
        },
      ],
    },
    {
      title: "Carregamentos Iniciais",
      items: [
        { to: "/insertESL", label: "Inserir novas ESLs" },
        { to: "/insertSGLN", label: "Inserir novos GLNs" },
        { to: "/insertGateway", label: "Inserir novos Gateways" },
      ],
    },
  ];

  return (
    <MDBNavbar expand="lg" dark style={{ backgroundColor: "#eaf3e2" }}>
      <MDBContainer fluid>
        <MDBNavbarBrand
          href="/home"
          style={{ color: "#062b35", width: "50px" }}
        >
          <img src={logoLeroy} alt="Torneira Leroy Merlin" width="100%" />
        </MDBNavbarBrand>
        <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon="bars" fas style={{ color: "#062b35" }} />
        </MDBNavbarToggler>
        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
            {navItems.map((item, index) => (
              <NavItem key={index} href={item.href}>
                {item.label}
              </NavItem>
            ))}
            {!isSAP &&
              dropdowns.map((dropdown, index) => (
                <MDBNavbarItem
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <DropdownMenu title={dropdown.title} items={dropdown.items} />
                </MDBNavbarItem>
              ))}
          </MDBNavbarNav>
          <div className="ms-auto">
            <MDBNavbarNav>
              <MDBNavbarItem style={{ display: "flex", alignItems: "center" }}>
                <MDBNavbarLink
                  onClick={logout}
                  style={{ color: "#062b35", fontWeight: "bold" }}
                >
                  <u>Logout</u>
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </div>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default Navbar;
