import styled from "styled-components";

const getBackgroundColor = (quantidade, min, max) => {
  const minColor = [203, 227, 181]; // Verde muito claro
  const maxColor = [120, 190, 32]; // Verde escuro

  const scale = min === max ? 1 : (quantidade - min) / (max - min);

  const getColorValue = (min, max) => Math.round(min + (max - min) * scale);

  const r = getColorValue(minColor[0], maxColor[0]);
  const g = getColorValue(minColor[1], maxColor[1]);
  const b = getColorValue(minColor[2], maxColor[2]);

  return `rgb(${r}, ${g}, ${b})`;
};

export const CardContainer = styled.div`
  background-color: ${(props) =>
    getBackgroundColor(props.quantidade, props.min, props.max)};
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Estica os itens para a mesma altura */
  border-radius: 20px;
  padding: 10px;
  flex: 1;
`;

export const HeaderContainer = styled.div`
  background-color: #78be20;
  color: #fff;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
`;
