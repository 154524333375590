import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import BarChart from "../../components/BarChart/BarChart";
import MixedChart from "../../components/MixedChat/MixedChart";
import Card from "../../components/Cards/Card"; // Certifique-se de importar seu componente Card corretamente
import { Container } from "./styles";
import { position } from "polished";

const Dashboard = () => {
  const barChartCommissionsData = {
    labels: [
      "Cerâmica",
      "Cozinha",
      "Decoração",
      "Elétrica",
      "Encanamento",
      "Ferragens",
      "Ferramentas",
      "Iluminação",
      "Jardim",
      "Madeira",
      "Material de Construção",
      "Organização",
      "Piso Quente",
      "Pintura",
      "Sanitário",
      "Tapetes",
    ],
    datasets: [
      {
        label: "ESLs comissionadas",
        data: [
          2225, 2000, 1980, 1800, 2600, 1500, 1700, 2500, 2000, 1800, 2200,
          1900, 2100, 2300, 2400, 2200,
        ],
        backgroundColor: "#78BE20",
      },
    ],
  };

  const barChartCommissionsOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Etiquetas Comissionadas por Seção",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  const mixedChartData = {
    labels: [
      "Cerâmica",
      "Cozinha",
      "Decoração",
      "Elétrica",
      "Encanamento",
      "Ferragens",
      "Ferramentas",
      "Iluminação",
      "Jardim",
      "Madeira",
      "Material de Construção",
      "Organização",
      "Piso Quente",
      "Pintura",
      "Sanitário",
      "Tapetes",
    ],
    datasets: [
      {
        type: "bar",
        label: "ESLs comissionadas",
        data: [
          2000, 2000, 1980, 1800, 2600, 1500, 1700, 2500, 2000, 1800, 2200,
          1900, 2100, 2300, 2400, 2200,
        ],
        backgroundColor: "#78BE20",
      },
      {
        type: "line",
        label: "Média de faces por LM",
        data: [8, 4, 8, 4, 9, 8, 9, 3, 5, 6, 3, 8, 7, 6, 5, 4],
        borderColor: "#78BE20",
        backgroundColor: "rgba(120, 190, 32, 0.2)",
        fill: false,
        tension: 0.1,
        yAxisID: "y1",
        datalabels: {
          align: "end",
          anchor: "end",
          offset: 10,
        },
      },
    ],
  };

  const mixedChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        //display: false,
        position: "top",
      },
      title: {
        display: true,
        text: "LM's Comissionados por Seção",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        display: false,
      },
      y1: {
        beginAtZero: true,
        display: false,
        min: 0,
        max: 10, // Ajuste o valor máximo conforme necessário
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  return (
    <Container fluid>
      <MDBRow className="mb-4" style={{ width: "100%" }}>
        <MDBCol md="3">
          <Card
            title="Nro de ESLs Comissionadas"
            number="40.000"
            icon={<MDBIcon fas icon="signal" style={{ color: "#87c53a" }} />}
            iconColor="#ffffff"
          />
        </MDBCol>
        <MDBCol md="3">
          <Card
            title="ESLs Não Comissionadas"
            number="3"
            icon={
              <MDBIcon
                fas
                icon="exclamation-triangle"
                style={{ color: "red" }}
              />
            }
            iconColor="#ffffff"
          />
        </MDBCol>
        <MDBCol md="3">
          <Card
            title="Nro de LM's Comissionados"
            number="35.000"
            icon={
              <MDBIcon fas icon="shopping-cart" style={{ color: "#87c53a" }} />
            }
            iconColor="#ffffff"
          />
        </MDBCol>
        <MDBCol md="3">
          <Card
            title="LM's Atualizados hoje"
            number="154"
            icon={<MDBIcon fas icon="signal" style={{ color: "#87c53a" }} />}
            iconColor="#ffffff"
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4" style={{ width: "100%" }}>
        <MDBCol md="6">
          <BarChart
            data={barChartCommissionsData}
            options={barChartCommissionsOptions}
          />
        </MDBCol>
        <MDBCol md="6">
          <MixedChart data={mixedChartData} options={mixedChartOptions} />
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default Dashboard;
