import styled from "styled-components";

export const TableContainer = styled.div`
  border-radius: 20px;
  padding: 20px;

  table {
    width: 100%;

    th {
      background-color: #eaf3e2;
      color: white;
      padding: 10px;
      color: #000;
    }

    tr:nth-child(odd) {
      background-color: #eff6ee;
    }

    tr:hover {
      background-color: rgb(
        173,
        213,
        133
      ) !important; /* Garante que a linha mude de cor */
    }
  }
`;

export const CircleCell = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fcd64c;
  color: #000;
  text-align: center;
  line-height: 20px;
`;

export const StatusCell = styled.td`
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Alinha o conteúdo à esquerda */

  svg {
    margin-right: 8px; /* Adiciona margem à direita do ícone */
  }

  span {
    margin-left: 8px; /* Adiciona margem à esquerda do texto */
  }
`;

export const ClickableRow = styled.tr`
  cursor: pointer;
`;
