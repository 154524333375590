import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "./styles";
import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdb-react-ui-kit";
import api from "../../services/api";
import { ButtonEye } from "../../components/ButtonEye/ButtonEye";

function UpdateShopsDetails() {
  const navigate = useNavigate();
  const [stores, setStores] = useState([]);

  useEffect(() => {
    api.get("/stores").then((response) => {
      const fetchedStores = response.data;

      console.log(fetchedStores);

      Promise.all(
        fetchedStores.map((store) =>
          api.get(`/infos/total/${store.client_id}/${store.code}`)
        )
      ).then((infoResponses) => {
        const storesWithInfos = fetchedStores.map((store, index) => ({
          ...store,
          infos: infoResponses[index].data,
        }));
        console.log(storesWithInfos);
        setStores(storesWithInfos);
      });
    });
  }, []);

  function handleRowClick(store) {
    navigate(
      `/storemapsecoes?client_id=${store.client_id}&store_code=${store.code}&location=${store.location}`
    );
  }

  return (
    <Container>
      <h3 className="d-flex align-items-left">Atualizações de produtos por lojas</h3>

      <MDBTable align="start" hover responsive>
        <MDBTableHead>
          <tr className="text-center">
            <th scope="col">Código</th>
            <th scope="col">Loja</th>
            <th scope="col">Produtos atualizados com sucesso</th>
            <th scope="col">Produtos atualizados com erro</th>
            <th scope="col">Porcentagem de produtos atualizados</th>
            <th scope="col">Ver detalhes</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {stores.map((store) => (
            <tr
              key={store.code}
              className="table-light text-center"
              onClick={() => handleRowClick(store)}
            >
              <td>{store.code}</td>
              <td>{store.location}</td>
              <td>{"Pegar dados"}</td>
              <td>{"Pegar dados"}</td>
              <td>{"12%"}</td>
              <td>
                <ButtonEye />
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </Container>
  );
}

export default UpdateShopsDetails;
