import React from "react";
import { Chart } from "react-chartjs-2";
import { ChartContainer } from "./styles";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const MixedChart = ({ data, options }) => {
  const updatedOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      datalabels: [
        {
          display: (context) => context.dataset.type === "bar",
          align: "center",
          anchor: "center",
          color: "#062b35",
          font: {
            weight: "normal",
          },
          rotation: 270,
          formatter: (value, context) => {
            return context.chart.data.labels[context.dataIndex];
          },
        },
        {
          display: (context) => context.dataset.type === "line",
          align: "end",
          anchor: "end",
          color: "#000",
          font: {
            weight: "bold",
          },
          offset: 10,
          formatter: (value) => value,
        },
      ],
      legend: {
        display: false, // Remover a legenda
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        display: false,
      },
      y1: {
        beginAtZero: true,
        display: false,
        min: 0,
        max: 10, // Defina o valor máximo de acordo com os dados da linha
      },
    },
  };

  return (
    <ChartContainer>
      <Chart data={data} options={updatedOptions} />
    </ChartContainer>
  );
};

export default MixedChart;
