// StoreMapCorredores.js
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, SectionSquare } from "./styles";
import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdb-react-ui-kit";
import "material-symbols";
import api from "../../services/api";
import { ButtonEye } from "../../components/ButtonEye/ButtonEye";
import {
  IoIosArrowDropupCircle,
  IoIosArrowDropdownCircle,
} from "react-icons/io";

function StoreMapCorredores() {
  const client_id = new URLSearchParams(useLocation().search).get("client_id");
  const store_code = new URLSearchParams(useLocation().search).get(
    "store_code"
  );
  const section = new URLSearchParams(useLocation().search)
    .get("profundidadeUm")
    .slice(0, 2);
  const location = new URLSearchParams(useLocation().search).get("location");

  const navigate = useNavigate();

  function handleRowClick(client_id, store_code, section, aisle) {
    navigate(
      `/storemapmodulos?client_id=${client_id}&store_code=${store_code}&location=${location}&profundidadeUm=${section}&profundidadeDois=${aisle}`
    );
  }

  const [infosSections, setInfosSections] = useState([]);
  const [columnSelectedSort, setColumnSelectedSort] = useState({
    columnName: "corridor",
    crescent: true,
  });

  useEffect(() => {
    api
      .get(
        `/infos/dataDetailsProfundidadeDois/${client_id}/${store_code}/${section}`
      )
      .then((response) => {
        setInfosSections(response.data);
      });
  }, [client_id, section, store_code]);

  function sortByColumn(fieldName, crescent = false, isNumber = true) {
    const aux = [...infosSections];

    if (isNumber) {
      if (fieldName === "commissionedESLs") {
        const auxSorted = aux.sort(
          (a, b) =>
            a.totalESLs -
            a.notCommissionedESLs -
            (b.totalESLs - b.notCommissionedESLs)
        );
        setInfosSections(crescent ? auxSorted : auxSorted.reverse());
        return;
      }

      const auxSorted = aux.sort((a, b) => {
        if (checkStringNumber(a[fieldName]) && checkStringNumber(b[fieldName]))
          return Number(a[fieldName]) - Number(b[fieldName]);
        return a[fieldName]
          .toLowerCase()
          .localeCompare(b[fieldName].toLowerCase());
      });
      setInfosSections(crescent ? auxSorted : auxSorted.reverse());
      return;
    }

    const auxSorted = aux.sort((a, b) =>
      a[fieldName].toLowerCase().localeCompare(b[fieldName].toLowerCase())
    );

    setInfosSections(crescent ? auxSorted : auxSorted.reverse());
  }

  function checkStringNumber(string) {
    return !isNaN(string);
  }

  return (
    <Container>
      <div style={{ display: "flex", marginBottom: 10, alignItems: "center" }}>
        <span
          style={{ fontSize: 14, cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/storemapsecoes?client_id=${client_id}&store_code=${store_code}&location=${location}`
            )
          }
        >
          {location}
        </span>
        <span style={{ marginLeft: 6, marginRight: 6, fontSize: 14 }}>
          {" "}
          {"/"}{" "}
        </span>
        <span
          style={{ fontSize: 14, cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/storemapcorredores?client_id=${client_id}&store_code=${store_code}&location=${location}&profundidadeUm=${section}`
            )
          }
        >
          {section}
        </span>
      </div>
      <h3 className="d-flex align-items-left">
        Corredores da Seção {section} da Loja {location}
      </h3>

      <MDBTable align="start" hover responsive>
        <MDBTableHead>
          <tr
            className="text-center"
            style={{ textAlign: "center", verticalAlign: "middle" }}
          >
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                const { columnName, crescent } = columnSelectedSort;
                sortByColumn(
                  "profundidadeDois",
                  columnName === "corridor" ? !crescent : true,
                  false
                );
                setColumnSelectedSort({
                  columnName: "corridor",
                  crescent: columnName === "corridor" ? !crescent : true,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Corredor</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "corridor" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ marginLeft: 5, position: "absolute" }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ marginLeft: 5, position: "absolute" }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                const { columnName, crescent } = columnSelectedSort;
                sortByColumn(
                  "commissionedESLs",
                  columnName === "commissionedESLs" ? !crescent : true
                );
                setColumnSelectedSort({
                  columnName: "commissionedESLs",
                  crescent:
                    columnName === "commissionedESLs" ? !crescent : true,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Número de Etiquetas comissionadas</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "commissionedESLs" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                const { columnName, crescent } = columnSelectedSort;
                sortByColumn(
                  "productsCommissioned",
                  columnName === "productsCommissioned" ? !crescent : true
                );
                setColumnSelectedSort({
                  columnName: "productsCommissioned",
                  crescent:
                    columnName === "productsCommissioned" ? !crescent : true,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Número de Produtos comissionados</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "productsCommissioned" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                const { columnName, crescent } = columnSelectedSort;
                sortByColumn(
                  "notCommissionedESLs",
                  columnName === "notCommissionedESLs" ? !crescent : true
                );
                setColumnSelectedSort({
                  columnName: "notCommissionedESLs",
                  crescent:
                    columnName === "notCommissionedESLs" ? !crescent : true,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>
                  Etiquetas somente instaladas <br />
                  (não comissionados)
                </span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "notCommissionedESLs" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th
              scope="col"
              style={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() => {
                const { columnName, crescent } = columnSelectedSort;
                sortByColumn(
                  "isntValidESLs",
                  columnName === "isntValidESLs" ? !crescent : true
                );
                setColumnSelectedSort({
                  columnName: "isntValidESLs",
                  crescent: columnName === "isntValidESLs" ? !crescent : true,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Etiquetas que necessitam de manutenção</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {columnSelectedSort.columnName === "isntValidESLs" &&
                  columnSelectedSort.crescent ? (
                    <IoIosArrowDropdownCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  ) : (
                    <IoIosArrowDropupCircle
                      style={{ position: "absolute", marginLeft: 10 }}
                      size={20}
                    />
                  )}
                </div>
              </div>
            </th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Ver Módulos
            </th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {infosSections.map((info) => (
            <tr key={info.profundidadeDois} className="table-light text-center">
              <td>{info.profundidadeDois}</td>
              <td>{info.totalESLs - info.notCommissionedESLs}</td>
              <td>{info.productsCommissioned}</td>
              <td>{info.notCommissionedESLs}</td>
              <td>{info.isntValidESLs}</td>
              <td
                onClick={() =>
                  handleRowClick(
                    info.client_id,
                    info.store_code,
                    info.profundidadeUm,
                    info.profundidadeDois
                  )
                }
              >
                <ButtonEye />
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>

      <MDBRow>
        {/*infosSections?.map((info) => (
          <MDBCol md="3">
            <SectionSquare
              onClick={() =>
                handleRowClick(
                  info[0].client_id,
                  info[0].store_code,
                  info.depth_0,
                  info.depth_1
                )
              }
            >
              <MDBRow>
                <h5
                  className="d-flex align-items-center"
                  style={{ display: "flex" }}
                >
                  <strong>Corredor: {info.depth_2}</strong>
                </h5>
              </MDBRow>
              <MDBRow>
                <p
                  className="d-flex align-items-center"
                  style={{ display: "flex" }}
                >
                  <span className="material-symbols-outlined">123</span>{" "}
                  <strong style={{ marginRight: "5px" }}>Instaladas:</strong>
                  {info[0].totalESLs} ESLs
                </p>
              </MDBRow>
              <MDBRow>
                <p
                  className="d-flex align-items-center"
                  style={{ display: "flex" }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ marginRight: "5px" }}
                  >
                    link
                  </span>{" "}
                  <strong style={{ marginRight: "5px" }}>Comissionadas:</strong>
                  {info[0].totalESLs - info[0].notCommissionedESLs} ESLs
                </p>
              </MDBRow>
              <MDBRow>
                <p
                  className="d-flex align-items-center"
                  style={{ display: "flex" }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ marginRight: "5px" }}
                  >
                    link_off
                  </span>{" "}
                  <strong style={{ marginRight: "5px" }}>
                    Não comissionadas:
                  </strong>
                  {info[0].notCommissionedESLs} ESLs
                </p>
              </MDBRow>
              <MDBRow>
                <p
                  className="d-flex align-items-center"
                  style={{ display: "flex" }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ marginRight: "5px" }}
                  >
                    heart_broken
                  </span>{" "}
                  <strong style={{ marginRight: "5px" }}>Manutenção:</strong>{" "}
                  {info[0].isntValidESLs} ESLs
                </p>
              </MDBRow>
            </SectionSquare>
          </MDBCol>
            ))*/}
      </MDBRow>
    </Container>
  );
}

export default StoreMapCorredores;
