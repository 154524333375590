/* eslint-disable react-hooks/exhaustive-deps */
// ESLs.js
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, ESLsSquare, Infos } from "./styles";
import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
} from "mdb-react-ui-kit";
import "material-symbols";
import api from "../../services/api";

import ButtonSequence from "../../components/ButtonSequence/ButtonSequence ";
import Dropdown from "../../components/Dropdown/Dropdown";
import ButtonGrey from "../../components/ButtonGrey/ButtonGrey";
import Input from "../../components/Input/Input";
import { IoIosArrowDropupCircle, IoIosArrowDropdownCircle } from "react-icons/io";
import moment from "moment";

function ESLs() {
  const navigate = useNavigate();
  const client_id = new URLSearchParams(useLocation().search).get("client_id");
  const store_code = new URLSearchParams(useLocation().search).get(
    "store_code"
  );
  const section = new URLSearchParams(useLocation().search)
    .get("profundidadeUm")
    .slice(0, 2);
  const aisle = new URLSearchParams(useLocation().search)
    .get("profundidadeDois")
    .slice(0, 2);
  const modules = new URLSearchParams(useLocation().search)
    .get("profundidadeTres")
    .slice(0, 2);

  const location = new URLSearchParams(useLocation().search).get("location");

  const [eslsByShelf, setEslsByShelf] = useState([]);
  const [esls, setEsls] = useState([]);
  const [status, setStatus] = useState("Carregando");
  const [filterVisible, setFilterVisible] = useState("none");
  const [stores, setStores] = useState([]);

  const [gtin, setGtin] = useState("");
  const [mac, setMac] = useState("");
  const [columnSelectedSort, setColumnSelectedSort] = useState({
    columnName: 'product_title',
    crescent: true
  })

  useEffect(() => {
    if (gtin.length === 13) {
      handleSearchGtin();
    }
  }, [gtin]);

  useEffect(() => {
    if (mac.length === 12) {
      handleSearchMac();
    }
  }, [mac]);

  function handleSearchGtin() {
    api
      .get(
        `/esls/details/gtin/${client_id}/${store_code}/${section}/${gtin}`
      )
      .then((response) => {
        setEslsByShelf(
          response.data.reduce((acc, cur) => {
            const level = cur.level.trim();
            const slot = cur.slot.trim();
            if (!acc[level]) {
              acc[level] = {};
            }
            if (!acc[level][slot]) {
              acc[level][slot] = [];
            }
            acc[level][slot].push(cur);
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        setEslsByShelf([]);
        setStatus("Erro");
      });
  }

  const handleClickNotCommissioned = () => {
    api
      .get(
        `/esls/details/notcommissioned/section/${client_id}/${store_code}/${section}`
      )
      .then((response) => {
        setEslsByShelf(
          response.data.reduce((acc, cur) => {
            const level = cur.level.trim();
            const slot = cur.slot.trim();
            if (!acc[level]) {
              acc[level] = {};
            }
            if (!acc[level][slot]) {
              acc[level][slot] = [];
            }
            acc[level][slot].push(cur);
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        setEslsByShelf([]);
        setStatus("Erro");
      });
  };

  const handleClickCommissioned = () => {
    api
      .get(
        `/esls/details/commissioned/section/${client_id}/${store_code}/${section}`
      )
      .then((response) => {
        setEslsByShelf(
          response.data.reduce((acc, cur) => {
            const level = cur.level.trim();
            const slot = cur.slot.trim();
            if (!acc[level]) {
              acc[level] = {};
            }
            if (!acc[level][slot]) {
              acc[level][slot] = [];
            }
            acc[level][slot].push(cur);
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        setEslsByShelf([]);
        setStatus("Erro");
      });
  };

  function handleSearchMac() {
    api.get(`/esls/details/mac/${mac}`).then((response) => {
      setEslsByShelf(
        response.data.reduce((acc, cur) => {
          const level = cur.level.trim();
          const slot = cur.slot.trim();
          if (!acc[level]) {
            acc[level] = {};
          }
          if (!acc[level][slot]) {
            acc[level][slot] = [];
          }
          acc[level][slot].push(cur);
          return acc;
        }, {})
      );
    });
  }

  function init() {
    api.get(`/stores`).then((response) => setStores(response.data));

    api
      .get(
        `/esls/detaislGropedByProduct?client_id=${client_id}&store_code=${store_code}&profundidadeUm=${section}&profundidadeDois=${aisle}&profundidadeTres=${modules}`
      )
      .then((response) => {
        const aux = []
        console.log(response.data)

        response.data.map(item => {
          item.esls.map(esl => aux.push({
            mac: esl.mac,
            product_title: item.product_title ? item.product_title : "Sem produto",
            product_gtin: item.product_gtin,
            product_price: esl.product_price ?? "Sem preço",
            commission_status: esl.commission_status === "K" ? "Comissionada" : "Não comissionada",
            last_update: esl.msg_timestamp ? esl.msg_timestamp : "Não possui"
            // depth_0: item.depth_0,
            // depth_1: item.depth_1,
            // depth_2: item.depth_2
          }))
        })
        setEsls(aux);
      });
  }

  useEffect(() => {
    init();
  }, [
    client_id,
    store_code,
    section,
    aisle,
    modules,
  ]);

  function handleSquareClick(mac) {
    navigate(`/details/${mac}`);
  }

  function sortByColumn(fieldName, crescent = false, isNumber = true) {
    const aux = [...esls]

    console.log(fieldName)
    console.log(aux[0])
    console.log(aux[0][fieldName])

    if (isNumber === 'date'){
      const auxSorted = aux.sort((a, b) => {

        if(checkStringDate(a[fieldName]) && checkStringDate(b[fieldName]))
          return new Date(a[fieldName]) - new Date(b[fieldName])
        return a[fieldName].toLowerCase().localeCompare(b[fieldName].toLowerCase())
      })
  
      setEsls(crescent ? auxSorted : auxSorted.reverse())
      return;
    }

    if (isNumber) {
      if (fieldName === 'commissionedESLs') {
        const auxSorted = aux.sort((a, b) => (a[0].totalESLs - a[0].notCommissionedESLs) - (b[0].totalESLs - b[0].notCommissionedESLs))
        setEsls(crescent ? auxSorted : auxSorted.reverse())
        return;
      }

      const auxSorted = aux.sort((a, b) => a[0][fieldName] - b[0][fieldName])
      setEsls(crescent ? auxSorted : auxSorted.reverse())
      return;
    }

    const auxSorted = aux.sort((a, b) => {

      if(checkStringNumber(a[fieldName]) && checkStringNumber(b[fieldName]))
        return Number(a[fieldName]) - Number(b[fieldName])
      return a[fieldName].toLowerCase().localeCompare(b[fieldName].toLowerCase())
    })

    setEsls(crescent ? auxSorted : auxSorted.reverse())
  }

  function checkStringNumber(string) {
    return !isNaN(string);
  }

  function checkStringDate(string) {
    // Expressão regular para validar o formato da data (YYYY-MM-DD)
    var regex = /^\d{4}-\d{2}-\d{2}$/;
    
    // Verificar se a string corresponde ao padrão da expressão regular
    if (!regex.test(string)) 
      return false;
    return true
  }

  return (
    <Container>
      <MDBRow>
        <MDBCol size="10">
        <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center'}}>
          <span style={{ fontSize: 14, cursor: 'pointer'}} onClick={() => navigate(`/storemapsecoes?client_id=${client_id}&store_code=${store_code}&location=${location}`)}>{location}</span>
          <span style={{ marginLeft: 6, marginRight: 6, fontSize: 14}}> {'/'} </span>
          <span style={{ fontSize: 14, cursor: 'pointer'}} onClick={() => navigate(`/storemapcorredores?client_id=${client_id}&store_code=${store_code}&location=${location}&profundidadeUm=${section}`)}>{section}</span>
          <span style={{ marginLeft: 6, marginRight: 6, fontSize: 14}}> {'/'} </span>
          <span style={{ fontSize: 14, cursor: 'pointer'}} onClick={() => navigate(`/storemapmodulos?client_id=${client_id}&store_code=${store_code}&location=${location}&profundidadeUm=${section}&profundidadeDois=${aisle}`)}>{aisle}</span>
          <span style={{ marginLeft: 6, marginRight: 6, fontSize: 14}}> {'/'} </span>
          <span style={{ fontSize: 14, cursor: 'pointer'}} onClick={() => navigate(`/esls?client_id=${client_id}&store_code=${store_code}&location=${location}&profundidadeUm=${section}&profundidadeDois=${aisle}&profundidadeTres=${modules}`)}>{modules}</span>
        </div>
          <h3 className="text-start">Etiquetas do Módulo {modules}</h3>
        </MDBCol>
        <MDBCol
          size="2"
          onClick={() => {
            if (filterVisible === "none") {
              setFilterVisible("flex");
            } else {
              init();
              setFilterVisible("none");
            }
          }}
        >
          <h3 className="text-end">
            {filterVisible === "none" ? (
              <button
                className="material-symbols-outlined"
                style={{ borderStyle: "none", backgroundColor: "transparent" }}
              >
                filter_alt
              </button>
            ) : (
              <button
                className="material-symbols-outlined"
                style={{ borderStyle: "none", backgroundColor: "transparent" }}
              >
                close
              </button>
            )}
          </h3>
        </MDBCol>
      </MDBRow>
      <Infos>
        <MDBRow className="text-start">
          <MDBCol lg="12">
            <MDBRow
              className="text-start"
              style={{ display: `${filterVisible}` }}
            >
              <MDBCol md="4">
                <h4>Mac:</h4>
                <Input
                  type="text"
                  autoComplete="mac"
                  name="mac"
                  placeholder="Insira o MAC de 12 dígitos"
                  value={mac}
                  onChange={(e) => setMac(e.target.value)}
                />
              </MDBCol>
              <MDBCol md="4">
                <h4>GTIN:</h4>
                <Input
                  type="text"
                  autoComplete="gtin"
                  name="gtin"
                  placeholder="Insira o GTIN de 13 dígitos"
                  value={gtin}
                  onChange={(e) => setGtin(e.target.value)}
                />
              </MDBCol>
              <MDBCol md="4">
                <h4>Situação:</h4>
                <MDBRow>
                  <MDBCol md="6">
                    <ButtonGrey
                      onClick={handleClickCommissioned}
                      label="Comissionadas"
                    />
                  </MDBCol>
                  <MDBCol md="6">
                    <ButtonGrey
                      onClick={handleClickNotCommissioned}
                      label="Não comissionadas"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            {/*filterVisible === "none" && (
              <MDBRow className="text-start">
                <MDBCol lg="4" style={{ display: "flex" }}>
                  <h4>Prateleira:</h4>
                  <ButtonSequence
                    numbers={shelves}
                    onButtonClick={handleButtonClickShelf}
                  />
                </MDBCol>
              </MDBRow>
            )*/}

            <MDBTable align="start" hover responsive>
              <MDBTableHead>
                <tr style={{ textAlign: 'center', verticalAlign: 'middle'}}>
                <th scope="col" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => {
                    const { columnName, crescent } = columnSelectedSort
                    sortByColumn('mac', columnName === 'mac' ? !crescent : true, false)
                    setColumnSelectedSort({
                      columnName: 'mac',
                      crescent: columnName === 'mac' ? !crescent : true
                    })
                  }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <span>Etiqueta Eletrônica (MAC)</span>
                      <div style={{ display: 'flex', alignItems: 'center'}}>{columnSelectedSort.columnName === 'mac' && columnSelectedSort.crescent ? <IoIosArrowDropdownCircle style={{ marginLeft: 5, position: 'absolute' }} size={20} /> : <IoIosArrowDropupCircle style={{ marginLeft: 5, position: 'absolute' }} size={20} />}</div>
                    </div></th>
                  <th scope="col" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => {
                    const { columnName, crescent } = columnSelectedSort
                    sortByColumn('product_title', columnName === 'product_title' ? !crescent : true, false)
                    setColumnSelectedSort({
                      columnName: 'product_title',
                      crescent: columnName === 'product_title' ? !crescent : true
                    })
                  }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <span>Produto (GTIN)</span>
                      <div style={{ display: 'flex', alignItems: 'center'}}>{columnSelectedSort.columnName === 'product_title' && columnSelectedSort.crescent ? <IoIosArrowDropdownCircle style={{ marginLeft: 5, position: 'absolute' }} size={20} /> : <IoIosArrowDropupCircle style={{ marginLeft: 5, position: 'absolute' }} size={20} />}</div>
                    </div></th>
                    <th scope="col" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => {
                    const { columnName, crescent } = columnSelectedSort
                    sortByColumn('product_price', columnName === 'product_price' ? !crescent : true, false)
                    setColumnSelectedSort({
                      columnName: 'product_price',
                      crescent: columnName === 'product_price' ? !crescent : true
                    })
                  }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <span>Preço atual</span>
                      <div style={{ display: 'flex', alignItems: 'center'}}>{columnSelectedSort.columnName === 'product_price' && columnSelectedSort.crescent ? <IoIosArrowDropdownCircle style={{ marginLeft: 5, position: 'absolute' }} size={20} /> : <IoIosArrowDropupCircle style={{ marginLeft: 5, position: 'absolute' }} size={20} />}</div>
                    </div></th>
                    <th scope="col" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => {
                    const { columnName, crescent } = columnSelectedSort
                    sortByColumn('last_update', columnName === 'last_update' ? !crescent : true, 'date')
                    setColumnSelectedSort({
                      columnName: 'last_update',
                      crescent: columnName === 'last_update' ? !crescent : true
                    })
                  }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <span>Data da última atualização</span>
                      <div style={{ display: 'flex', alignItems: 'center'}}>{columnSelectedSort.columnName === 'last_update' && columnSelectedSort.crescent ? <IoIosArrowDropdownCircle style={{ marginLeft: 5, position: 'absolute' }} size={20} /> : <IoIosArrowDropupCircle style={{ marginLeft: 5, position: 'absolute' }} size={20} />}</div>
                    </div></th>
                    <th scope="col" style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => {
                    const { columnName, crescent } = columnSelectedSort
                    sortByColumn('commission_status', columnName === 'commission_status' ? !crescent : true, 'date')
                    setColumnSelectedSort({
                      columnName: 'commission_status',
                      crescent: columnName === 'commission_status' ? !crescent : true
                    })
                  }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                      <span>Status</span>
                      <div style={{ display: 'flex', alignItems: 'center'}}>{columnSelectedSort.columnName === 'commission_status' && columnSelectedSort.crescent ? <IoIosArrowDropdownCircle style={{ marginLeft: 5, position: 'absolute' }} size={20} /> : <IoIosArrowDropupCircle style={{ marginLeft: 5, position: 'absolute' }} size={20} />}</div>
                    </div></th>
                  <th scope="col" style={{ fontWeight: 'bold'}}>Detalhes</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {esls.length === 0 ? (
                  <tr>
                    <td colSpan="5">
                      <p className="text-center" style={{ marginTop: "1%" }}>
                        Nenhuma ESL encontrada.
                      </p>
                    </td>
                  </tr>
                ) : (
                  esls.map((esl) => (
                    <tr
                      key={esl.mac}
                      className="table-light text-center"
                    >
                      <td>
                        {esl.mac}
                      </td>
                      <td>{esl.product_title}<br/>{esl.product_gtin ? `(${esl.product_gtin})` : ""}</td>
                      <td>{typeof esl.product_price === "number" ? `R$ ${esl.product_price.toString().replace(".", ",")}` : esl.product_price}</td>
                      <td>{esl.last_update === "Não possui" ? esl.last_update : moment.utc(esl.last_update).format("DD/MM/YYYY")}</td>
                      <td>{esl.commission_status}</td>
                      <td onClick={() => handleSquareClick(esl.mac)}>
                        <MDBIcon
                          icon="eye"
                          fas
                          onClick={() => {
                            if(esl.product_gtin)
                              handleSquareClick(esl.mac)
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </MDBTableBody>
            </MDBTable>
          </MDBCol>
        </MDBRow>
      </Infos>
    </Container>
  );
}

export default ESLs;
