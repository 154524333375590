import styled from "styled-components";

export const ButtonContainer = styled.div`
  position: relative;
  display: inline-block;
  background-color: #78be20;
  color: #000;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  button {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  background-color: #78be20;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 20px;
  top: 40px; /* Ajuste conforme necessário */
  left: 0;
  z-index: 1;
  width: 100%; /* Ajuste conforme necessário */
`;

export const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #ccc;
    color: #000;
  }
`;
