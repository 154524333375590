// UpdatePricesSections.js
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../components/Cards/Card";
import PriceUpdateTable from "../../components/PriceUpdateTable/PriceUpdateTable";
import CardText from "../../components/CardText/CardText";
import ColumnWithCards from "../../components/ColumnWithCards/ColumnWithCards";
import {
  Container,
  HeaderContainer,
  LastUpdateText,
  ContentContainer,
  Title,
} from "./styles";
import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import moment from "moment";
import PeriodButton from "../../components/PeriodButton/PeriodButton";
import DropdownMenu from "../../components/DropdownMenu/DropdownMenu";

const tableData = [
  {
    status: "Com Erro",
    name: "Cerâmica",
    updatedLMs: 98,
    lastUpdate: "2024-06-12",
    errorCount: 2,
  },
  {
    status: "Em Andamento",
    name: "Cozinha",
    updatedLMs: 26,
    lastUpdate: "2024-06-12",
    errorCount: 0,
  },
  {
    status: "Em Andamento",
    name: "Decoração",
    updatedLMs: 78,
    lastUpdate: "2024-06-12",
    errorCount: 0,
  },
  {
    status: "há 10 horas",
    name: "Elétrica",
    updatedLMs: 6,
    lastUpdate: "2024-06-12",
    errorCount: 2,
  },
  {
    status: "Em Andamento",
    name: "Encanação",
    updatedLMs: 55,
    lastUpdate: "2024-06-12",
    errorCount: 0,
  },
  {
    status: "Em Andamento",
    name: "Ferragem",
    updatedLMs: 17,
    lastUpdate: "2024-06-12",
    errorCount: 2,
  },
  {
    status: "Em Andamento",
    name: "Ferramenta",
    updatedLMs: 60,
    lastUpdate: "2024-06-12",
    errorCount: 0,
  },
  {
    status: "Com Erro",
    name: "Iluminação",
    updatedLMs: 5,
    lastUpdate: "2024-06-12",
    errorCount: 2,
  },
  {
    status: "Em Andamento",
    name: "Jardim",
    updatedLMs: 14,
    lastUpdate: "2024-06-12",
    errorCount: 0,
  },
  {
    status: "Em Andamento",
    name: "Madeira",
    updatedLMs: 98,
    lastUpdate: "2024-06-12",
    errorCount: 0,
  },
  {
    status: "Em Andamento",
    name: "Mat. Const.",
    updatedLMs: 77,
    lastUpdate: "2024-06-12",
    errorCount: 0,
  },
  {
    status: "Com Erro",
    name: "Organização",
    updatedLMs: 42,
    lastUpdate: "2024-06-12",
    errorCount: 2,
  },
  {
    status: "Em Andamento",
    name: "Piso Quente",
    updatedLMs: 2,
    lastUpdate: "2024-06-12",
    errorCount: 0,
  },
  {
    status: "há 2 horas",
    name: "Pintura",
    updatedLMs: 74,
    lastUpdate: "2024-06-12",
    errorCount: 0,
  },
  {
    status: "Em Andamento",
    name: "Pódio",
    updatedLMs: 61,
    lastUpdate: "2024-06-12",
    errorCount: 0,
  },
  {
    status: "Com Erro",
    name: "Sanitário",
    updatedLMs: 57,
    lastUpdate: "2024-06-12",
    errorCount: 2,
  },
  {
    status: "Em Andamento",
    name: "Tapete",
    updatedLMs: 23,
    lastUpdate: "2024-06-12",
    errorCount: 0,
  },
];

const LMData = [
  {
    title: "Semana",
    items: [
      { produto: "Mart Comb 800W HR2470 220V+acess Makita", quantidade: 26 },
      { produto: "ARG ACIII MULTIUSO CZ 20KG AXTON", quantidade: 18 },
      { produto: "Rodape LEV102 Frisado Bco 240x10cm", quantidade: 18 },
      { produto: "MANTA AXTON 2MM 20,4M2", quantidade: 28 },
      { produto: "MANTA AXTON 2MM 20,4M2", quantidade: 50 },
      { produto: "Kit Vaso Sanit C/Cx 3/6L Vert Elite Br", quantidade: 15 },
    ],
  },
  {
    title: "Mês",
    items: [
      { produto: "Mart Comb 800W HR2470 220V+acess Makita", quantidade: 21 },
      { produto: "ARG ACIII MULTIUSO CZ 20KG AXTON", quantidade: 29 },
      { produto: "Rodape LEV102 Frisado Bco 240x10cm", quantidade: 0 },
      { produto: "MANTA AXTON 2MM 20,4M2", quantidade: 23 },
      { produto: "MANTA AXTON 2MM 20,4M2", quantidade: 41 },
      { produto: "Kit Vaso Sanit C/Cx 3/6L Vert Elite Br", quantidade: 32 },
    ],
  },
  {
    title: "3 Meses",
    items: [
      { produto: "Mart Comb 800W HR2470 220V+acess Makita", quantidade: 24 },
      { produto: "ARG ACIII MULTIUSO CZ 20KG AXTON", quantidade: 45 },
      { produto: "Rodape LEV102 Frisado Bco 240x10cm", quantidade: 12 },
      { produto: "MANTA AXTON 2MM 20,4M2", quantidade: 50 },
      { produto: "MANTA AXTON 2MM 20,4M2", quantidade: 42 },
      { produto: "Kit Vaso Sanit C/Cx 3/6L Vert Elite Br", quantidade: 20 },
    ],
  },
];

function UpdatePricesSections() {
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [endDate, setEndDate] = useState(moment());

  const { idStore } = useParams();

  const [selectedItem, setSelectedItem] = useState(null);
  const items = [
    "Todas",
    "Cerâmica",
    "Cozinha",
    "Decoração",
    "Elétrica",
    "Encanamento",
    "Ferragem",
    "Ferramenta",
    "Iluminação",
    "Jardim",
    "Madeira",
  ];

  const handleDatesChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const navigate = useNavigate();

  const handleRowClick = (item) => {
    navigate(`/updatePricesErrors/${idStore}/${item.name}`);
  };

  useEffect(() => {
    if (startDate && endDate) {
      console.log(
        "Período selecionado:",
        startDate.format("DD/MM/YYYY"),
        "-",
        endDate.format("DD/MM/YYYY")
      );
    }
  }, [startDate, endDate]);

  return (
    <Container>
      <MDBRow>
        <MDBCol sm="12" md="3" className="mb-4" style={{ color: "#87c53a" }}>
          <Card
            title="Atualizados com Sucesso"
            number="793"
            icon={<MDBIcon fas icon="check-circle" />}
            iconColor="#ffffff"
          />
        </MDBCol>
        <MDBCol sm="12" md="3" className="mb-4">
          <Card
            title="Atualizações com Falhas"
            number="12"
            icon={
              <MDBIcon fas icon="exclamation-circle" style={{ color: "red" }} />
            }
            iconColor="#ffffff"
          />
        </MDBCol>
        <MDBCol sm="12" md="3" className="mb-4">
          <Card
            title="Data"
            number="12/06/2023"
            icon={<MDBIcon far icon="calendar" style={{ color: "#87c53a" }} />}
            iconColor="#ffffff"
          />
        </MDBCol>
        <MDBCol sm="12" md="3" className="mb-4">
          <Card
            title="LM atualizado há 6+ meses"
            number="MANTA AXTON 2MM 20,4M2"
            icon={<MDBIcon fas icon="signal" style={{ color: "#87c53a" }} />}
            iconColor="#ffffff"
          />
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol sm="12" md="7" className="mb-4">
          <ContentContainer>
            <HeaderContainer>
              <Title>Atualizações de preços nas seções de {idStore}</Title>
              <PeriodButton
                startDate={startDate}
                endDate={endDate}
                onDatesChange={handleDatesChange}
              />
            </HeaderContainer>
            <LastUpdateText>
              Última atualização de preços ocorrida há 10 horas - às 16:45 |
              12/06/2024
            </LastUpdateText>
            <PriceUpdateTable
              tipo={"Seção"}
              data={tableData}
              onRowClick={handleRowClick}
            />
          </ContentContainer>
        </MDBCol>
        <MDBCol sm="12" md="5" className="mb-4">
          <ContentContainer>
            <HeaderContainer>
              <DropdownMenu
                title={"Seção"}
                items={items}
                selectedItem={selectedItem}
                onItemSelect={setSelectedItem}
              />
              <Title>LM’s Mais e Menos Atualizados</Title>
            </HeaderContainer>
            <MDBRow>
              <MDBCol sm="12" md="4" className="mb-4">
                <CardText text="Semana" />
              </MDBCol>
              <MDBCol sm="12" md="4" className="mb-4">
                <CardText text="Mês" />
              </MDBCol>
              <MDBCol sm="12" md="4" className="mb-4">
                <CardText text="3 Meses" />
              </MDBCol>
            </MDBRow>
            <ColumnWithCards data={LMData} />
          </ContentContainer>
        </MDBCol>
      </MDBRow>
    </Container>
  );
}

export default UpdatePricesSections;
