import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Container, PendingIcon } from "./styles";
import ButtonGreen from "../../components/ButtonGreen/ButtonGreen";
import Input from "../../components/Input/Input";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import Select from "../../components/Select/Select";

const InserirAVRO = () => {
  const [name, setName] = useState("");
  const [gtin, setGtin] = useState("");
  const [rating, setRating] = useState();
  const [price, setPrice] = useState();
  const [materialCode, setMaterialCode] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [AVROs, setAVROs] = useState([
    {
      topic:
        "adeo-uat1-southamerica-east1-APP-REVIONICS-LM-BR-P1-C3-QUEUEING-TAG-SALES-PRICE-INBOUND-V1",
      timestamp: 1687537440058,
      partition: 0,
      offset: 1766746,
      key: "GW4AE3RI7jw4l4jt6Ukr2m",
      value: {
        transactionId: "GW4AE3RI7jw4l4jt6UkL2m",
        businessUnitCode: 7,
        salesOrganizationCode: "LB01",
        distributionChannelCode: "10",
        plantCode: "0045",
        materialCode: "000000000089368944",
        salesPackagingCode: "UN",
        salesPackagingQuantity: 1.0,
        salesPackagingBarcodeNumber: "7898265335677",
        salesPriceCurrencyCode: "BRL",
        plantMaterialFacingQuantity: 1,
        materialFullDescription: "CIMENTO CPII 32 TODAS AS OBRA 50KG VOT",
        materialAssortmentCode: "L",
        hierarchySectionNodeCode: "01",
        logicalStockMaterialQuantity: 572.0,
        materialTopCode: "1",
        salesPriceCondition: [
          {
            salesPriceConditionEndDate: "9999/12/31",
            salesPriceConditionStartDate: "2023/06/23",
            specialPriceReasonCode: null,
            grossRegularSalesPriceUnitAmount: 30.9,
            grossSalesPriceCondition: [
              {
                salesPriceConditionType: "VKP0",
                materialSalesPriceUnitAmount: 30.9,
              },
            ],
            netSalesPriceCondition: [
              {
                salesPriceConditionType: "ZPVL",
                materialSalesPriceUnitAmount: 28.04,
              },
            ],
          },
        ],
        hierarchySubsectionNodeCode: "0105",
        hierarchyTypeNodeCode: "010501",
        hierarchySubtypeNodeCode: "01050101",
        materialReviewRatingQuantity: 106,
        materialReviewRatingAverage: 4.8019,
      },
    },
    {
      topic:
        "adeo-uat1-southamerica-east1-APP-REVIONICS-LM-BR-P1-C3-QUEUEING-TAG-SALES-PRICE-INBOUND-V1",
      timestamp: 1687537459655,
      partition: 0,
      offset: 1766747,
      key: "GW4AE3RF7jw4l4pQ7t1QVW",
      value: {
        transactionId: "GW4AE3RF7jw4l4pQ7t0wVW",
        businessUnitCode: 7,
        salesOrganizationCode: "LB01",
        distributionChannelCode: "10",
        plantCode: "0045",
        materialCode: "000000000089998916",
        salesPackagingCode: "UN",
        salesPackagingQuantity: 1.0,
        salesPackagingBarcodeNumber: "7896902178618",
        salesPriceCurrencyCode: "BRL",
        plantMaterialFacingQuantity: 1,
        materialFullDescription: "Kit Vaso Sanit C/Cx 3/6L Vert Elite Br",
        materialAssortmentCode: "A",
        hierarchySectionNodeCode: "07",
        logicalStockMaterialQuantity: 55.0,
        materialTopCode: "1",
        salesPriceCondition: [
          {
            salesPriceConditionEndDate: "9999/12/31",
            salesPriceConditionStartDate: "2023/06/23",
            specialPriceReasonCode: null,
            grossRegularSalesPriceUnitAmount: 1209.0,
            grossSalesPriceCondition: [
              {
                salesPriceConditionType: "VKP0",
                materialSalesPriceUnitAmount: 1209.0,
              },
            ],
            netSalesPriceCondition: [
              {
                salesPriceConditionType: "ZPVL",
                materialSalesPriceUnitAmount: 1097.17,
              },
            ],
          },
        ],
        hierarchySubsectionNodeCode: "0707",
        hierarchyTypeNodeCode: "070756",
        hierarchySubtypeNodeCode: "07075601",
        materialReviewRatingQuantity: 0,
        materialReviewRatingAverage: 0.0,
      },
    },
    {
      topic:
        "adeo-uat1-southamerica-east1-APP-REVIONICS-LM-BR-P1-C3-QUEUEING-TAG-SALES-PRICE-INBOUND-V1",
      timestamp: 1687537478871,
      partition: 0,
      offset: 1766748,
      key: "GW4AE3RF7jw4l4vYufYws0",
      value: {
        transactionId: "GW4AE3RF7jw4l4vYufYQs0",
        businessUnitCode: 7,
        salesOrganizationCode: "LB01",
        distributionChannelCode: "10",
        plantCode: "0045",
        materialCode: "000000000088403616",
        salesPackagingCode: "UN",
        salesPackagingQuantity: 1.0,
        salesPackagingBarcodeNumber: "7898055528906",
        salesPriceCurrencyCode: "BRL",
        plantMaterialFacingQuantity: 1,
        materialFullDescription: "Viaplus 7000 - fibras cx 18kg",
        materialAssortmentCode: "A",
        hierarchySectionNodeCode: "01",
        logicalStockMaterialQuantity: 361.0,
        materialTopCode: "1",
        salesPriceCondition: [
          {
            salesPriceConditionEndDate: "9999/12/31",
            salesPriceConditionStartDate: "2023/06/23",
            specialPriceReasonCode: null,
            grossRegularSalesPriceUnitAmount: 394.9,
            grossSalesPriceCondition: [
              {
                salesPriceConditionType: "VKP0",
                materialSalesPriceUnitAmount: 394.9,
              },
            ],
            netSalesPriceCondition: [
              {
                salesPriceConditionType: "ZPVL",
                materialSalesPriceUnitAmount: 394.9,
              },
            ],
          },
        ],
        hierarchySubsectionNodeCode: "0130",
        hierarchyTypeNodeCode: "013022",
        hierarchySubtypeNodeCode: "01302202",
        materialReviewRatingQuantity: 44,
        materialReviewRatingAverage: 4.8864,
      },
    },
    {
      topic:
        "adeo-uat1-southamerica-east1-APP-REVIONICS-LM-BR-P1-C3-QUEUEING-TAG-SALES-PRICE-INBOUND-V1",
      timestamp: 1687537507594,
      partition: 0,
      offset: 1766750,
      key: "GW4AE3RS7jw4l527nLj9L0",
      value: {
        transactionId: "GW4AE3RS7jw4l527nLifL0",
        businessUnitCode: 7,
        salesOrganizationCode: "LB01",
        distributionChannelCode: "10",
        plantCode: "0045",
        materialCode: "000000000090861610",
        salesPackagingCode: "UN",
        salesPackagingQuantity: 1.0,
        salesPackagingBarcodeNumber: "7898348536922",
        salesPriceCurrencyCode: "BRL",
        plantMaterialFacingQuantity: 1,
        materialFullDescription: "FECHADURA DIGITAL DE EMBUTIR WI-FI COM B",
        materialAssortmentCode: "A",
        hierarchySectionNodeCode: "10",
        logicalStockMaterialQuantity: 11.0,
        materialTopCode: "1",
        salesPriceCondition: [
          {
            salesPriceConditionEndDate: "9999/12/31",
            salesPriceConditionStartDate: "2023/06/23",
            specialPriceReasonCode: null,
            grossRegularSalesPriceUnitAmount: 1099.9,
            grossSalesPriceCondition: [
              {
                salesPriceConditionType: "VKP0",
                materialSalesPriceUnitAmount: 1099.9,
              },
            ],
            netSalesPriceCondition: [
              {
                salesPriceConditionType: "ZPVL",
                materialSalesPriceUnitAmount: 998.16,
              },
            ],
          },
        ],
        hierarchySubsectionNodeCode: "1004",
        hierarchyTypeNodeCode: "100403",
        hierarchySubtypeNodeCode: "10040305",
        materialReviewRatingQuantity: 0,
        materialReviewRatingAverage: 0.0,
      },
    },
    {
      topic:
        "adeo-uat1-southamerica-east1-APP-REVIONICS-LM-BR-P1-C3-QUEUEING-TAG-SALES-PRICE-INBOUND-V1",
      timestamp: 1687537531687,
      partition: 0,
      offset: 1766753,
      key: "GW4AE3RS7jw4l59KNCw9TW",
      value: {
        transactionId: "GW4AE3RS7jw4l59KNCvfTW",
        businessUnitCode: 7,
        salesOrganizationCode: "LB01",
        distributionChannelCode: "10",
        plantCode: "0045",
        materialCode: "000000000086186695",
        salesPackagingCode: "UN",
        salesPackagingQuantity: 1.0,
        salesPackagingBarcodeNumber: "7894200113836",
        salesPriceCurrencyCode: "BRL",
        plantMaterialFacingQuantity: 3,
        materialFullDescription: "Duch 1j acqua plus 1990cstd-deca",
        materialAssortmentCode: "A",
        hierarchySectionNodeCode: "08",
        logicalStockMaterialQuantity: 43.0,
        materialTopCode: "1",
        salesPriceCondition: [
          {
            salesPriceConditionEndDate: "9999/12/31",
            salesPriceConditionStartDate: "2023/06/23",
            specialPriceReasonCode: null,
            grossRegularSalesPriceUnitAmount: 699.9,
            grossSalesPriceCondition: [
              {
                salesPriceConditionType: "VKP0",
                materialSalesPriceUnitAmount: 699.9,
              },
            ],
            netSalesPriceCondition: [
              {
                salesPriceConditionType: "ZPVL",
                materialSalesPriceUnitAmount: 635.16,
              },
            ],
          },
        ],
        hierarchySubsectionNodeCode: "0804",
        hierarchyTypeNodeCode: "080426",
        hierarchySubtypeNodeCode: "08042604",
        materialReviewRatingQuantity: 0,
        materialReviewRatingAverage: 0.0,
      },
    },
    {
      topic:
        "adeo-uat1-southamerica-east1-APP-REVIONICS-LM-BR-P1-C3-QUEUEING-TAG-SALES-PRICE-INBOUND-V1",
      timestamp: 1687537554212,
      partition: 0,
      offset: 1766755,
      key: "GW4AE3RS7jw4l5G0uqS9X0",
      value: {
        transactionId: "GW4AE3RS7jw4l5G0uqRfX0",
        businessUnitCode: 7,
        salesOrganizationCode: "LB01",
        distributionChannelCode: "10",
        plantCode: "0045",
        materialCode: "000000000090775482",
        salesPackagingCode: "UN",
        salesPackagingQuantity: 1.0,
        salesPackagingBarcodeNumber: "7891444944233",
        salesPriceCurrencyCode: "BRL",
        plantMaterialFacingQuantity: 1,
        materialFullDescription: "Rodape LEV102 Frisado Bco 240x10cm",
        materialAssortmentCode: "A",
        hierarchySectionNodeCode: "02",
        logicalStockMaterialQuantity: 215.0,
        materialTopCode: "1",
        salesPriceCondition: [
          {
            salesPriceConditionEndDate: "9999/12/31",
            salesPriceConditionStartDate: "2023/06/23",
            specialPriceReasonCode: null,
            grossRegularSalesPriceUnitAmount: 95.9,
            grossSalesPriceCondition: [
              {
                salesPriceConditionType: "VKP0",
                materialSalesPriceUnitAmount: 95.9,
              },
            ],
            netSalesPriceCondition: [
              {
                salesPriceConditionType: "ZPVL",
                materialSalesPriceUnitAmount: 87.03,
              },
            ],
          },
        ],
        hierarchySubsectionNodeCode: "0250",
        hierarchyTypeNodeCode: "025050",
        hierarchySubtypeNodeCode: "02505001",
        materialReviewRatingQuantity: 0,
        materialReviewRatingAverage: 0.0,
      },
    },
    {
      topic:
        "adeo-uat1-southamerica-east1-APP-REVIONICS-LM-BR-P1-C3-QUEUEING-TAG-SALES-PRICE-INBOUND-V1",
      timestamp: 1687537565776,
      partition: 0,
      offset: 1766756,
      key: "GW4AE3RS7jw4l5JUnYx9bW",
      value: {
        transactionId: "GW4AE3RS7jw4l5JUnYwfbW",
        businessUnitCode: 7,
        salesOrganizationCode: "LB01",
        distributionChannelCode: "10",
        plantCode: "0045",
        materialCode: "000000000090351765",
        salesPackagingCode: "UN",
        salesPackagingQuantity: 1.0,
        salesPackagingBarcodeNumber: "0088381735643",
        salesPriceCurrencyCode: "BRL",
        plantMaterialFacingQuantity: 0,
        materialFullDescription: "Mart Comb 800W HR2470 220V+acess Makita",
        materialAssortmentCode: "A",
        hierarchySectionNodeCode: "04",
        logicalStockMaterialQuantity: 62.0,
        materialTopCode: "1",
        salesPriceCondition: [
          {
            salesPriceConditionEndDate: "9999/12/31",
            salesPriceConditionStartDate: "2023/06/23",
            specialPriceReasonCode: null,
            grossRegularSalesPriceUnitAmount: 1009.0,
            grossSalesPriceCondition: [
              {
                salesPriceConditionType: "VKP0",
                materialSalesPriceUnitAmount: 1009.0,
              },
            ],
            netSalesPriceCondition: [
              {
                salesPriceConditionType: "ZPVL",
                materialSalesPriceUnitAmount: 915.67,
              },
            ],
          },
        ],
        hierarchySubsectionNodeCode: "0403",
        hierarchyTypeNodeCode: "040320",
        hierarchySubtypeNodeCode: "04032030",
        materialReviewRatingQuantity: 0,
        materialReviewRatingAverage: 0.0,
      },
    },
    {
      topic:
        "adeo-uat1-southamerica-east1-APP-REVIONICS-LM-BR-P1-C3-QUEUEING-TAG-SALES-PRICE-INBOUND-V1",
      timestamp: 1687537577814,
      partition: 0,
      offset: 1766757,
      key: "GW4AE3RS7jw4l5N3VYz9dW",
      value: {
        transactionId: "GW4AE3RS7jw4l5N3VYyfdW",
        businessUnitCode: 7,
        salesOrganizationCode: "LB01",
        distributionChannelCode: "10",
        plantCode: "0045",
        materialCode: "000000000090537230",
        salesPackagingCode: "UN",
        salesPackagingQuantity: 1.0,
        salesPackagingBarcodeNumber: "7908225701937",
        salesPriceCurrencyCode: "BRL",
        plantMaterialFacingQuantity: 1,
        materialFullDescription: "Kit Spot Led Trilho PT 9W L.AM Biv Insp",
        materialAssortmentCode: "A",
        hierarchySectionNodeCode: "13",
        logicalStockMaterialQuantity: 67.0,
        materialTopCode: "1",
        salesPriceCondition: [
          {
            salesPriceConditionEndDate: "9999/12/31",
            salesPriceConditionStartDate: "2023/06/23",
            specialPriceReasonCode: null,
            grossRegularSalesPriceUnitAmount: 219.9,
            grossSalesPriceCondition: [
              {
                salesPriceConditionType: "VKP0",
                materialSalesPriceUnitAmount: 219.9,
              },
            ],
            netSalesPriceCondition: [
              {
                salesPriceConditionType: "ZPVL",
                materialSalesPriceUnitAmount: 199.56,
              },
            ],
          },
        ],
        hierarchySubsectionNodeCode: "1301",
        hierarchyTypeNodeCode: "130103",
        hierarchySubtypeNodeCode: "13010320",
        materialReviewRatingQuantity: 16,
        materialReviewRatingAverage: 4.6875,
      },
    },
    {
      topic:
        "adeo-uat1-southamerica-east1-APP-REVIONICS-LM-BR-P1-C3-QUEUEING-TAG-SALES-PRICE-INBOUND-V1",
      timestamp: 1687537591957,
      partition: 0,
      offset: 1766758,
      key: "GW4AE3RF7jw4l5R8RDexc0",
      value: {
        transactionId: "GW4AE3RF7jw4l5R8RDeRc0",
        businessUnitCode: 7,
        salesOrganizationCode: "LB01",
        distributionChannelCode: "10",
        plantCode: "0045",
        materialCode: "000000000089707016",
        salesPackagingCode: "UN",
        salesPackagingQuantity: 1.0,
        salesPackagingBarcodeNumber: "7898961902326",
        salesPriceCurrencyCode: "BRL",
        plantMaterialFacingQuantity: 1,
        materialFullDescription: "Cloro 5 em 1 Multiação 10Kg Axton",
        materialAssortmentCode: "A",
        hierarchySectionNodeCode: "09",
        logicalStockMaterialQuantity: 51.0,
        materialTopCode: "1",
        salesPriceCondition: [
          {
            salesPriceConditionEndDate: "9999/12/31",
            salesPriceConditionStartDate: "2023/06/23",
            specialPriceReasonCode: null,
            grossRegularSalesPriceUnitAmount: 309.9,
            grossSalesPriceCondition: [
              {
                salesPriceConditionType: "VKP0",
                materialSalesPriceUnitAmount: 309.9,
              },
            ],
            netSalesPriceCondition: [
              {
                salesPriceConditionType: "ZPVL",
                materialSalesPriceUnitAmount: 230.61,
              },
            ],
          },
        ],
        hierarchySubsectionNodeCode: "0980",
        hierarchyTypeNodeCode: "098002",
        hierarchySubtypeNodeCode: "09800202",
        materialReviewRatingQuantity: 0,
        materialReviewRatingAverage: 0.0,
      },
    },
    {
      topic:
        "adeo-uat1-southamerica-east1-APP-REVIONICS-LM-BR-P1-C3-QUEUEING-TAG-SALES-PRICE-INBOUND-V1",
      timestamp: 1687537615819,
      partition: 0,
      offset: 1766760,
      key: "GW4AE3RI7jw4l5YHvkFtd0",
      value: {
        transactionId: "GW4AE3RI7jw4l5YHvkFNd0",
        businessUnitCode: 7,
        salesOrganizationCode: "LB01",
        distributionChannelCode: "10",
        plantCode: "0045",
        materialCode: "000000000089296172",
        salesPackagingCode: "UN",
        salesPackagingQuantity: 1.0,
        salesPackagingBarcodeNumber: "7898449140066",
        salesPriceCurrencyCode: "BRL",
        plantMaterialFacingQuantity: 1,
        materialFullDescription: "ARG ACIII MULTIUSO CZ 20KG AXTON",
        materialAssortmentCode: "A",
        hierarchySectionNodeCode: "01",
        logicalStockMaterialQuantity: 2285.0,
        materialTopCode: "1",
        salesPriceCondition: [
          {
            salesPriceConditionEndDate: "9999/12/31",
            salesPriceConditionStartDate: "2023/06/23",
            specialPriceReasonCode: null,
            grossRegularSalesPriceUnitAmount: 41.9,
            grossSalesPriceCondition: [
              {
                salesPriceConditionType: "VKP0",
                materialSalesPriceUnitAmount: 41.9,
              },
            ],
            netSalesPriceCondition: [
              {
                salesPriceConditionType: "ZPVL",
                materialSalesPriceUnitAmount: 41.9,
              },
            ],
          },
        ],
        hierarchySubsectionNodeCode: "0105",
        hierarchyTypeNodeCode: "010515",
        hierarchySubtypeNodeCode: "01051593",
        materialReviewRatingQuantity: 0,
        materialReviewRatingAverage: 0.0,
      },
    },
    {
      topic:
        "adeo-uat1-southamerica-east1-APP-REVIONICS-LM-BR-P1-C3-QUEUEING-TAG-SALES-PRICE-INBOUND-V1",
      timestamp: 1687537629399,
      partition: 0,
      offset: 1766761,
      key: "GW4AE3RS7jw4l5cRzHD9qG",
      value: {
        transactionId: "GW4AE3RS7jw4l5cRzHCfqG",
        businessUnitCode: 7,
        salesOrganizationCode: "LB01",
        distributionChannelCode: "10",
        plantCode: "0045",
        materialCode: "000000000091710444",
        salesPackagingCode: "UN",
        salesPackagingQuantity: 1.0,
        salesPackagingBarcodeNumber: "7909569346105",
        salesPriceCurrencyCode: "BRL",
        plantMaterialFacingQuantity: 1,
        materialFullDescription: "Purificador refrig pla PE12G biv cz-elec",
        materialAssortmentCode: "B",
        hierarchySectionNodeCode: "08",
        logicalStockMaterialQuantity: 2.0,
        materialTopCode: "1",
        salesPriceCondition: [
          {
            salesPriceConditionEndDate: "9999/12/31",
            salesPriceConditionStartDate: "2023/06/23",
            specialPriceReasonCode: null,
            grossRegularSalesPriceUnitAmount: 739.9,
            grossSalesPriceCondition: [
              {
                salesPriceConditionType: "VKP0",
                materialSalesPriceUnitAmount: 739.9,
              },
            ],
            netSalesPriceCondition: [
              {
                salesPriceConditionType: "ZPVL",
                materialSalesPriceUnitAmount: 671.46,
              },
            ],
          },
        ],
        hierarchySubsectionNodeCode: "0805",
        hierarchyTypeNodeCode: "080555",
        hierarchySubtypeNodeCode: "08055520",
        materialReviewRatingQuantity: 0,
        materialReviewRatingAverage: 0.0,
      },
    },
    {
      topic:
        "adeo-uat1-southamerica-east1-APP-REVIONICS-LM-BR-P1-C3-QUEUEING-TAG-SALES-PRICE-INBOUND-V1",
      timestamp: 1687537653928,
      partition: 0,
      offset: 1766763,
      key: "GW4AE3RI7jw4l5jYjmaOEm",
      value: {
        transactionId: "GW4AE3RI7jw4l5jYjmZuEm",
        businessUnitCode: 7,
        salesOrganizationCode: "LB01",
        distributionChannelCode: "10",
        plantCode: "0045",
        materialCode: "000000000089218990",
        salesPackagingCode: "UN",
        salesPackagingQuantity: 1.0,
        salesPackagingBarcodeNumber: "7899622849417",
        salesPriceCurrencyCode: "BRL",
        plantMaterialFacingQuantity: 1,
        materialFullDescription: "MANTA AXTON 2MM 20,4M2",
        materialAssortmentCode: "A",
        hierarchySectionNodeCode: "05",
        logicalStockMaterialQuantity: 66.0,
        materialTopCode: "1",
        salesPriceCondition: [
          {
            salesPriceConditionEndDate: "9999/12/31",
            salesPriceConditionStartDate: "2023/06/23",
            specialPriceReasonCode: null,
            grossRegularSalesPriceUnitAmount: 189.9,
            grossSalesPriceCondition: [
              {
                salesPriceConditionType: "VKP0",
                materialSalesPriceUnitAmount: 189.9,
              },
            ],
            netSalesPriceCondition: [
              {
                salesPriceConditionType: "ZPVL",
                materialSalesPriceUnitAmount: 172.34,
              },
            ],
          },
        ],
        hierarchySubsectionNodeCode: "0550",
        hierarchyTypeNodeCode: "055010",
        hierarchySubtypeNodeCode: "05501010",
        materialReviewRatingQuantity: 0,
        materialReviewRatingAverage: 0.0,
      },
    },
  ]);
  const [selectionProductName, setSelectionProductName] = useState("");

  const formatValue = (value) => {
    const numericValue = value.replace(/[^0-9.,]/g, "");
    const dotValue = numericValue.replace(/,/g, ".");
    return dotValue;
  };

  useEffect(() => {
    const selectedProduct = AVROs.find(
      (option) => option.value.materialFullDescription === selectionProductName
    );

    if (selectedProduct) {
      setGtin(selectedProduct.value.salesPackagingBarcodeNumber);
      setRating(selectedProduct.value.materialReviewRatingAverage || 0.0);
      setPrice(
        selectedProduct.value.salesPriceCondition[0].grossSalesPriceCondition[0]
          .materialSalesPriceUnitAmount
      );
      setMaterialCode(selectedProduct.value.materialCode);
    }
  }, [AVROs, selectionProductName]);

  const handleSelectChangeProductName = (value) => {
    setSelectionProductName(value);
    setName(value);
  };

  useEffect(() => {
    const SAP = localStorage.getItem("code");

    if (SAP !== "012345678910") {
      navigate("/");
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Chamar a API
      if (
        name === "" ||
        gtin === "" ||
        rating === "" ||
        price === "" ||
        materialCode === ""
      ) {
        toast.error("Preencha todos os campos");
      } else {
        setLoading(true); // Ativar o estado de carregamento
        api
          .post(`/productUpdate/sendAVRO`, {
            name,
            gtin,
            price: Number(price),
            rating: Number(rating),
            materialCode,
          })
          .then((response) => {
            toast.success(`Atualização do GTIN ${gtin} enviada com sucesso!`);
            setLoading(false);
          })
          .catch((error) => {
            toast.error(
              `${JSON.parse(error.request.response).message} ${name}`
            );
            setLoading(false);
          });
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log("err.message", err.message);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <h5 style={{ marginTop: "15px" }}>
        Simulação de troca de preços pelo SAP
      </h5>
      <Container>
        <MDBRow>
          <MDBCol
            lg="12"
            className="text-start"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <form onSubmit={handleSubmit} style={{ marginTop: "-1%" }}>
              <h6>Nome Produto</h6>
              <Select
                label="Selecione o produto"
                options={AVROs.map((option) => ({
                  value: option.value.materialFullDescription,
                  label: option.value.materialFullDescription,
                }))}
                selection={selectionProductName}
                onChange={handleSelectChangeProductName}
              />
              <h6>GTIN</h6>
              <Input
                type="text"
                autoComplete="gtin"
                name="gtin"
                value={gtin || ""}
                onChange={(event) => setGtin(event.target.value)}
                disabled
              />
              <h6>Preço (editável)</h6>
              <Input
                type="number"
                autoComplete="price"
                name="price"
                value={price || ""}
                onChange={(event) => setPrice(formatValue(event.target.value))}
              />
              <h6>Avaliação (editável)</h6>
              <Input
                type="number"
                autoComplete="rating"
                name="rating"
                value={rating !== null ? rating : 0.0}
                onChange={(event) => setRating(formatValue(event.target.value))}
              />
              <h6>Código do Material</h6>
              <Input
                type="text"
                autoComplete="materialCode"
                name="materialCode"
                value={materialCode || ""}
                onChange={(event) => setMaterialCode(event.target.value)}
                disabled
              />
              <MDBRow>
                <MDBCol>
                  <ButtonGreen type="submit" label="Enviar" />
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCol>
        </MDBRow>
      </Container>
      {loading && (
        <>
          <PendingIcon className="loading-icon" />
          <h5>Enviando solicitação</h5>
        </>
      )}

      <h6>
        Esta tela trata-se de uma simulação de disparar atualizações de preço no
        SAP. Ela <strong>NÃO</strong> fará parte do sistema final. É uma solução
        temporária para o processo de homologação.
      </h6>
    </>
  );
};

export default InserirAVRO;
