import React from "react";
import {
  CardContainer,
  IconContainer,
  Title,
  Number,
  CardContent,
  TextContainer,
} from "./styles";

const Card = ({ title, number, icon, iconColor, onClick }) => {
  return (
    <CardContainer onClick={() => onClick()}>
      <CardContent>
        {icon && <IconContainer iconColor={iconColor}>{icon}</IconContainer>}
        <TextContainer>
          <Title>{title}</Title>
          <Number>{number}</Number>
        </TextContainer>
      </CardContent>
    </CardContainer>
  );
};

export default Card;
