import { VictoryChart, VictoryLine, VictoryTheme } from 'victory'

const ProductChart = () => {
    return (
        <div style={{ padding: 100}}>
            <VictoryChart
            >
                <VictoryLine
                    style={{
                        data: { stroke: "#41A017" },
                    }}
                    data={[
                        { x: 1, y: 2 },
                        { x: 2, y: 3 },
                        { x: 3, y: 5 },
                        { x: 4, y: 4 },
                        { x: 5, y: 7 }
                    ]}
                />
            </VictoryChart>
        </div>
    )
}

export default ProductChart