import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: inline-block;
  background-color: #78be20;
  color: #000;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  cursor: default;
  text-align: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-top: 20px;

  &:hover {
    background-color: #78be20;
  }
`;
